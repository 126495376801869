import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { HotelRoomSelectorComponent } from './hotel-room-selector.component';

@NgModule({
  declarations: [HotelRoomSelectorComponent],
  imports: [CommonModule, SharedModule],
  exports: [HotelRoomSelectorComponent],
})
export class HotelRoomSelectorModule {}
