import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LoadingComponent } from '../loading/loading.component';
import { SharedModule } from '../shared/shared.module';
import { CalendarComponent } from './calendar.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';

@NgModule({
  declarations: [CalendarComponent, DateSelectorComponent],
  imports: [CommonModule, SharedModule, FormsModule, LoadingComponent],
  exports: [CalendarComponent],
  providers: [DatePipe],
})
export class CalendarModule {}
