import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { map, Observable, of, tap } from 'rxjs';
import {
  CalendarMap,
  DateInfo,
} from 'src/app/components/booking-calendar/booking-calendar.component';
import { FlightSelectorComponent } from 'src/app/components/booking-calendar/flight-selector/flight-selector.component';
import { AuthService } from 'src/lib/auth/auth.service';
import { BookingApi } from 'src/lib/repository/booking/booking.api';
import { IPackagegoods } from 'src/lib/repository/packagegoods/packagegoods.model';
import { Utils } from 'src/lib/utils';
import { DialogService } from './dialog.service';

export const pointMemberPeriod: { start: string; end: string }[] = [
  // { start: '2023-01-30 10:00:00', end: '2023-02-01 09:59:59' },
  // { start: '2023-02-13 10:00:00', end: '2023-02-15 09:59:59' },
];

export const peakPeriodList = [
  // { start: '2023-04-30', end: '2023-05-07' },
  // { start: '2023-07-16', end: '2023-08-31' },
  // { start: '2023-09-27', end: '2023-10-05' },
];

export const numberToDowObject: Record<number, string> = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
};

export interface OnlineSetting {
  SUNDAY: number[];
  MONDAY: number[];
  TUESDAY: number[];
  WEDNESDAY: number[];
  THURSDAY: number[];
  FRIDAY: number[];
  SATURDAY: number[];
}

/**
 * 2024년도 예약
 *
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class Package2024Service {
  // availStartingPackageList: any[];

  // availReturningPackageList: any[];

  departingFlight: any;

  returnFlight: any;

  packagegoods: any;

  get isApp(): boolean {
    return Utils.isMobileSize();
  }

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private bookingService: BookingApi,
  ) {}

  canMakeBookingToday$(): Observable<boolean> {
    return of(true);
  }

  /**
   * 2023 예약 적립금 소지자는 등급 관계 없어 상품 이용 가능
   */
  getPackagegoodsUserClassParam(): any {
    return {};
  }

  openFlightSelectModal(
    flights: any[],
    callback: (f: any) => void,
    title = 'ALERT.Input_Flight',
    titlePrefix?: string,
  ): void {
    if (!flights?.length) {
      callback(null);
      return;
    }

    if (flights.length === 1) {
      callback(flights[0]);
      return;
    }

    this.dialogService
      .open(
        FlightSelectorComponent,
        { flights, title, titlePrefix },
        {
          // panelClass: this.isApp ? null : 'flight-selector',
        },
      )
      .onDialogClosed.pipe(
        tap((f) => {
          callback(f);
        }),
      )
      .subscribe();
  }

  isPointMemberTime(date: Date = new Date()): boolean {
    const isPointMemberTime = pointMemberPeriod.some((period) => {
      if (
        date.valueOf() >= new Date(period.start).valueOf() &&
        date.valueOf() <= new Date(period.end).valueOf()
      ) {
        return true;
      }

      return false;
    });

    return isPointMemberTime;
  }

  getDateInfoList(
    calendarMap: CalendarMap,
    startDate: string,
    returnDate: string,
  ): DateInfo[] {
    const dateInfoList: DateInfo[] = [];
    const startDayjs = dayjs(startDate);
    const diff = dayjs(returnDate).diff(startDayjs, 'day');
    let countingDayjs = startDayjs.clone();

    // 귀국일은 제외하기 위해 diff - 1
    for (let i = 0; i <= diff - 1; i += 1) {
      const dateInfo = calendarMap.get(countingDayjs.format('YYYY-MM-DD'));
      dateInfoList.push(dateInfo!);

      countingDayjs = countingDayjs.add(1, 'day');
    }

    return dateInfoList;
  }

  isBookingCntOk$(startDate: string, returnDate: string): Observable<boolean> {
    return of(true);
  }

  getGolfTournamentMessage(startDate: string, returnDate: string): string {
    return null!;
  }

  getDefaultTourNumber$(): Observable<number> {
    return this.authService.getAccount$().pipe(
      map((account) => {
        const { customerClass } = account?.userInfo;
        let tourNumberForOneMembership = 4;

        /* if (
          customerClass === '특별' ||
          userClass === '특별' ||
          customerClass === '골드' ||
          userClass === '골드'
        ) {
          tourNumberForOneMembership = 2;
        } */

        return tourNumberForOneMembership;
      }),
    );
  }

  isAvailStartDate(startDate: string, packagegoods: IPackagegoods): boolean {
    if (!startDate || !packagegoods) {
      return false;
    }

    // if (new Date(startDate) >= new Date('2023-12-08')) {
    //   return false;
    // }

    if (
      !packagegoods.onlineSettingJson ||
      this.isOnlineSettingsEmpty(packagegoods.onlineSettingJson)
    ) {
      return true;
    }

    const availTourTermList = this.getAvailTourTermList(
      startDate,
      packagegoods.onlineSettingJson,
    );

    return availTourTermList.length > 0;
  }

  isAvailReturnDate(
    startDate: string,
    returnDate: string,
    packagegoods: IPackagegoods,
  ): boolean {
    if (!startDate || !returnDate || !packagegoods) {
      return false;
    }

    if (
      !packagegoods.onlineSettingJson ||
      this.isOnlineSettingsEmpty(packagegoods.onlineSettingJson)
    ) {
      return true;
    }

    const availTourTermList = this.getAvailTourTermList(
      startDate,
      packagegoods.onlineSettingJson,
    );

    /* const { customerClass } = this.authService.loginInfo;

    if (customerClass === '특별') {
      availTourTermList = availTourTermList.filter(
        (availTourTerm) => availTourTerm < 8
      );
    } else if (customerClass === 'EWRC이용권') {
      const isPeak = this.isPeriod2(startDate, returnDate);

      if (isPeak) {
        availTourTermList = availTourTermList.filter(
          (availTourTerm) => availTourTerm < 8
        );
      }
    } */

    let canReturn = false;

    availTourTermList.forEach((availTourTerm) => {
      const startDateDayjs = dayjs(startDate);
      const returnDateDayjs = dayjs(returnDate);
      const tourTermAddedDayjs = startDateDayjs.add(availTourTerm, 'day');
      canReturn = returnDateDayjs.isSame(tourTermAddedDayjs) || canReturn;
    });

    return canReturn;
  }

  isClosed(packagegoods: any, date: string): boolean {
    if (!packagegoods) {
      return false;
    }

    // if (packagegoods.id === 1) {
    //   const isAsoClosed =
    //     new Date(date) >= new Date('2023-03-12') &&
    //     new Date(date) <= new Date('2023-05-31');

    //   return isAsoClosed;
    // }

    // if (packagegoods.id === 2) {
    //   const isNagoyaClosed =
    //     new Date(date) >= new Date('2023-03-12') &&
    //     new Date(date) <= new Date('2023-04-30');

    //   return isNagoyaClosed;
    // }

    return false;
  }

  private isPeriod2(startDate: string, returnDate: string): boolean {
    const period2List = peakPeriodList.filter(({ start, end }) => {
      return (
        new Date(start) < new Date(returnDate) &&
        new Date(end) > new Date(startDate)
      );
    });

    if (period2List?.length > 0) {
      return true;
    }

    return false;
  }

  private isPeriod3(startDate: string, returnDate: string): boolean {
    return (
      new Date(startDate) >= new Date('2023-08-31') &&
      new Date(returnDate) <= new Date('2023-12-10')
    );
  }

  /**
   * Online 설정 없는지 확인
   */
  private isOnlineSettingsEmpty(onlineSettingJson: string): boolean {
    try {
      const onlineSetting: OnlineSetting = JSON.parse(onlineSettingJson);

      return Object.values(onlineSetting).every((dayList) => !dayList.length);
    } catch (error) {
      return true;
    }
  }

  private getAvailTourTermList(
    startDate: string,
    onlineSettingJson: string,
  ): number[] {
    let onlineSetting: OnlineSetting;

    try {
      onlineSetting = JSON.parse(onlineSettingJson);
    } catch (error) {
      return [];
    }

    const dayOfWeekNumber = dayjs(startDate).get('day');
    const dayOfWeek = numberToDowObject[dayOfWeekNumber];
    const availDow = Object.keys(onlineSetting).filter(
      (key) => key === dayOfWeek,
    )[0] as keyof OnlineSetting;

    if (!availDow) {
      return [];
    }

    return onlineSetting[availDow];
  }
}
