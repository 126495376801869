<!-- FIXME: ngClass 삭제 -->

<div [style.visibility]="canShow ? 'visible' : 'hidden'">
  <div class="calendar-control" [ngClass]="device$ | async">
    <div class="year-select">
      <select
        class="form-select"
        [ngModel]="year"
        (ngModelChange)="onYearSelectChange($event)"
      >
        <option *ngFor="let y of yearList" [value]="y">
          {{ y }}{{ 'year' | translate }}
        </option>
      </select>
    </div>
    <div class="month-select">
      <select
        class="form-select"
        [ngModel]="month"
        (ngModelChange)="onMonthSelectChange($event)"
      >
        <option *ngFor="let m of monthList" [value]="m">
          {{ +m + 1 }}{{ 'month' | translate }}
        </option>
      </select>
    </div>
    <!-- <div>
      <button
        class="btn btn-primary btn-search"
        (click)="onSearchButtonClick()"
      >
        {{ 'BUTTON.Search' | translate }}
      </button>
    </div> -->
  </div>

  @if (data?.size) {
    <div class="table-wrapper">
      <table class="calendar-table" [ngClass]="device$ | async">
        <thead class="head">
          <tr>
            <th>{{ 'Sun' | translate }}</th>
            <th>{{ 'Mon' | translate }}</th>
            <th>{{ 'Tue' | translate }}</th>
            <th>{{ 'Wed' | translate }}</th>
            <th>{{ 'Thu' | translate }}</th>
            <th>{{ 'Fri' | translate }}</th>
            <th>{{ 'Sat' | translate }}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let c of calendar">
            @for (d of c; track $index) {
              @if (!isExtraDate(d) || (isExtraDate(d) && startDate)) {
                @if (getText(d); as calendarDate) {
                  <td
                    [ngClass]="{
                      disabled:
                        isBeforeToday(d) ||
                        calendarDate.disabled === true ||
                        hasTourTerm(d),
                      start: d === startDate && startDate !== returnDate,
                      end: d === returnDate && startDate !== returnDate,
                      'day-tour': d === startDate && startDate === returnDate,
                      empty: !d,
                      in: isIn(d),
                      'today on': d === today,
                      'date-set': startDate && returnDate
                    }"
                    (click)="onDateColumnClick(d)"
                  >
                    <svg
                      class="background"
                      *ngIf="startDate !== returnDate"
                      [ngClass]="{ end: d === returnDate }"
                    >
                      <use
                        *ngIf="d === startDate"
                        href="/assets/imgs/bg_start_date.svg#path"
                      />
                      <use
                        *ngIf="d === returnDate"
                        href="/assets/imgs/bg_end_date.svg#path"
                      />
                    </svg>
                    <!-- <div
                      class="background-round"
                      [ngClass]="{ in: isIn(d) }"
                      *ngIf="isIn(d)"
                    ></div> -->
                    <div
                      class="background-round-large"
                      *ngIf="d === startDate && d === returnDate"
                    ></div>
                    <div class="date-wrap">
                      <span
                        class="date"
                        [ngClass]="{
                          weekend: isWeekend(d) || isHoliday(d)
                        }"
                      >
                        @if (isExtraDate(d)) {
                          {{ d | date: 'M/d' }}
                        } @else {
                          {{ d | date: 'd' }}
                        }
                      </span>
                      <span
                        class="cont"
                        [ngClass]="{
                          'multi-line': getText(d)['isMultiLine'],
                          close: getText(d)['close']
                        }"
                        >{{ getText(d).text ?? '' | translate }}</span
                      >
                    </div>
                  </td>
                }
              } @else {
                <td class="disabled" *ngIf="getText(d) as calendarDate"></td>
              }
            }
          </tr>
        </tbody>
      </table>
    </div>
  } @else {
    <div
      class="table-wrapper d-flex justify-content-center align-items-center"
      style="height: 30vh"
    >
      <!-- <span class="fs-5">{{ '조회 기간 선택 후 검색' | translate }}</span> -->
    </div>
  }
</div>
