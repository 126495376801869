import { Component, ElementRef, HostBinding } from '@angular/core';
import { IFlights } from 'src/lib/repository/flights/flights.model';
import { Utils } from 'src/lib/utils';
import { DialogRef } from '../../dialog-ref';
import { DialogAbstract } from '../../dialog.abstract';

@Component({
  selector: 'app-flight-selector',
  templateUrl: './flight-selector.component.html',
  styleUrls: ['./flight-selector.component.scss'],
})
export class FlightSelectorComponent extends DialogAbstract {
  override dialogRef!: DialogRef<
    this,
    IFlights,
    { flights: IFlights[]; title: string }
  >;

  selectedFlight?: IFlights;

  @HostBinding('class.app')
  get isApp(): boolean {
    return Utils.isMobileSize();
  }

  constructor(protected override elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  onFlightItemClick(flight: IFlights): void {
    this.dialogRef.close(flight);
  }

  onOkButtonClick(flight: IFlights): void {
    this.onFlightItemClick(flight);
  }

  onCloseButtonClick(): void {
    this.dialogRef.close();
  }

  totalTimes(startTime: string, endTime: string): string {
    const splitedStart = startTime.split(':');
    const startDate = new Date(0, 0, 0, +splitedStart[0], +splitedStart[1]);

    const splitedEnd = endTime.split(':');
    const endDate = new Date(0, 0, 0, +splitedEnd[0], +splitedEnd[1]);

    endDate.setHours(
      endDate.getHours() - startDate.getHours(),
      endDate.getMinutes() - startDate.getMinutes(),
    );

    const minutes = endDate.getMinutes();
    const hours = endDate.getHours();

    return `${hours}시간 ${minutes}분`;
  }
}
