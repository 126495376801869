import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { AbstractYearMonthSelectorComponent } from 'src/lib/components/abstract/abstract-year-month-selector.component';

@Component({
  selector: 'trnty-booking-calendar-year-month-selector',
  templateUrl: './booking-calendar-year-month-selector.component.html',
  styleUrls: ['./booking-calendar-year-month-selector.component.scss'],
})
export class BookingCalendarYearMonthSelectorComponent
  extends AbstractYearMonthSelectorComponent
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setYearList();
  }

  /**
   * 연도 목록을 현재년도, 내년도로 설정
   */
  private setYearList(): void {
    const thisYear = dayjs().year();
    this.yearList = [thisYear, thisYear + 1];
  }
}
