import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { HotelMrhstSelectorComponent } from './hotel-mrhst-selector.component';

@NgModule({
  declarations: [HotelMrhstSelectorComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  exports: [HotelMrhstSelectorComponent],
})
export class HotelMrhstSelectorModule {}
