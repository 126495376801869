<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="flight-select-modal" *ngIf="!isApp">
      <div class="flight-select-guide">
        {{ '귀국일을 선택해주세요' }}
      </div>

      <a class="close-button-wrapper" (click)="onCloseButtonClick()">
        <img
          class="close-button"
          src="/assets/publishing/include/images/ico/ico_close01.png"
        />
      </a>

      <div>
        <div class="flight-header">
          <div>귀국일</div>
        </div>
        <a
          *ngFor="let date of dialogRef.data!.dateList; index as i"
          class="flight-body"
          (click)="onDateItemClick(date)"
        >
          <div>
            {{ getNights(date) + '박 ' + (getNights(date) + 1) + '일 투어' }}
          </div>
          <div>{{ date }}</div>
        </a>
      </div>
    </div>
    <ng-container *ngIf="isApp">
      <div class="modal-header">
        <i class="bi bi-calendar-week"></i>
        <span>{{ '귀국일을 선택해주세요' }}</span>
      </div>
      <div class="modal-body">
        <div class="list-group list-group-flush">
          <button
            type="button"
            class="list-group-item list-group-item-action"
            [ngClass]="{ selected: selectedDate === date }"
            *ngFor="let date of dialogRef.data!.dateList"
            (click)="selectedDate = date"
          >
            <div class="time-wrapper">
              <span class="time-range">{{ date }}</span>
              <span class="time"
                >({{
                  getNights(date) + '박 ' + (getNights(date) + 1) + '일 일정'
                }})</span
              >
            </div>
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedDate"
          (click)="onOkButtonClick(selectedDate)"
        >
          확인
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="onCloseButtonClick()"
        >
          취소
        </button>
      </div>
    </ng-container>
  </div>
</div>
