<div class="row gap-2 w-100">
  <button
    class="col bg-transparent"
    (click)="onArrowClick(-1)"
    *ngIf="hasArrow"
  >
    <i class="bi bi-chevron-left align-middle"></i>
  </button>

  <select
    class="form-select form-select-sm col"
    [disabled]="isDisabled"
    [ngModel]="selectedYear"
    (ngModelChange)="onYearChange($event)"
  >
    <option [value]="year" *ngFor="let year of yearList">
      {{ year }}
    </option>
  </select>

  <select
    class="form-select form-select-sm col"
    [disabled]="isDisabled"
    [ngModel]="selectedMonth"
    (ngModelChange)="onMonthChange($event)"
    *ngIf="hasMonth"
  >
    <option [value]="month" *ngFor="let month of monthList">
      {{ month + 1 }}
    </option>
  </select>

  <button class="col bg-transparent" (click)="onArrowClick(1)" *ngIf="hasArrow">
    <i class="bi bi-chevron-right align-middle"></i>
  </button>
</div>
