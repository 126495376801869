import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { TourNumberModule } from '../tour-number/tour-number.module';
import { RoomNumberSelectorComponent } from './room-number-selector.component';

@NgModule({
  declarations: [RoomNumberSelectorComponent],
  imports: [CommonModule, SharedModule, FormsModule, TourNumberModule],
  exports: [RoomNumberSelectorComponent],
})
export class RoomNumberSelectorModule {}
