<div class="calendar-wrapper text-center">
  <div class="row row-cols-7 bg-body-tertiary">
    <div class="col p-1 text-danger">{{ 'WEEKDAY.sun' | translate }}</div>
    <div class="col p-1">{{ 'WEEKDAY.mon' | translate }}</div>
    <div class="col p-1">{{ 'WEEKDAY.tue' | translate }}</div>
    <div class="col p-1">{{ 'WEEKDAY.wed' | translate }}</div>
    <div class="col p-1">{{ 'WEEKDAY.thu' | translate }}</div>
    <div class="col p-1">{{ 'WEEKDAY.fri' | translate }}</div>
    <div class="col p-1 text-danger">
      {{ 'WEEKDAY.sat' | translate }}
    </div>
  </div>

  <!-- 일자 행 -->
  <div class="row row-cols-7" *ngFor="let dateList of date2dList">
    <!-- 일자 열 -->
    <div
      *ngFor="let date of dateList; first as first; last as last"
      class="col p-0"
      [class.hoverable]="canClickDate && date"
      [class.selected]="canClickDate && date && date === selectedDate"
      [class.text-danger]="first || last"
      (click)="onDateClick(date)"
    >
      <ng-container *ngIf="date">
        <ng-template #viewContainerRef></ng-template>
      </ng-container>
    </div>
  </div>
</div>
