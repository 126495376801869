import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractCalendarComponent } from 'src/lib/components/abstract/abstract-calendar.component';

@Component({
  selector: 'trnty-booking-calendar-type1',
  templateUrl: './booking-calendar-type1.component.html',
  styleUrls: ['./booking-calendar-type1.component.scss'],
})
export class BookingCalendarType1Component
  extends AbstractCalendarComponent
  implements OnInit
{
  constructor(protected override changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {}

  override onDateClick(date: string): void {
    // 컴포넌트 외부에서 실행하는 경우 일자 없을수 있음
    if (!date) {
      return;
    }

    // 클릭 불가능하면
    if (!this.canClickDate) {
      return;
    }

    const component = this.componentByDateMap?.get(date);

    // 선택 불가면
    if (component?.isDisabled) {
      return;
    }

    this.selectedDate = date;
    this.dateClick.emit(component);
  }
}
