import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DirectivesModule } from 'src/lib/directives/directives.module';
import { BookingConfirmModule } from '../booking-confirm/booking-confirm.module';
import { CalendarModule } from '../calendar/calendar.module';
import { HotelMrhstSelectorModule } from '../hotel-mrhst-selector/hotel-mrhst-selector.module';
import { HotelRoomSelectorModule } from '../hotel-room-selector/hotel-room-selector.module';
import { RoomNumberSelectorModule } from '../room-number-selector/room-number-selector.module';
import { SharedModule } from '../shared/shared.module';
import { TourNumberModule } from '../tour-number/tour-number.module';
import { BookingCalendarType1DataComponent } from './booking-calendar-type1-data/booking-calendar-type1-data.component';
import { BookingCalendarType1Component } from './booking-calendar-type1/booking-calendar-type1.component';
import { BookingCalendarYearMonthSelectorComponent } from './booking-calendar-year-month-selector/booking-calendar-year-month-selector.component';
import { BookingCalendarComponent } from './booking-calendar.component';
import { FlightSelectorComponent } from './flight-selector/flight-selector.component';

@NgModule({
  declarations: [
    BookingCalendarComponent,
    FlightSelectorComponent,
    BookingCalendarType1Component,
    BookingCalendarType1DataComponent,
    BookingCalendarYearMonthSelectorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CalendarModule,
    FormsModule,
    TourNumberModule,
    RoomNumberSelectorModule,
    HotelMrhstSelectorModule,
    HotelRoomSelectorModule,
    DirectivesModule,
    BookingConfirmModule,
  ],
  exports: [
    BookingCalendarComponent,
    FlightSelectorComponent,
    BookingCalendarType1Component,
    BookingCalendarType1DataComponent,
    BookingCalendarYearMonthSelectorComponent,
  ],
})
export class BookingCalendarModule {}
