import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { Utils } from 'src/lib/utils';
// import { RoomBookingService } from '../../repository/room-booking/room-booking.service';
// import { Package2023Service } from '../../services/package2023.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isNil } from 'lodash-es';
import { BookingDataService } from 'src/app/services/booking-data.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ShirosatoService } from 'src/app/services/shirosato-2024.service';
import { UiService } from 'src/app/services/ui.service';
import { AuthService } from 'src/lib/auth/auth.service';
import { BookingApi } from 'src/lib/repository/booking/booking.api';
import { HotelAssignApiService } from 'src/lib/repository/hotel-assign/hotel-assign.api';
import { HotelGoodsApi } from 'src/lib/repository/hotel-goods/hotel-goods.api';
import {
  IHotelGoods,
  IHotelGoodsListDto,
} from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { IPackagegoods } from 'src/lib/repository/packagegoods/packagegoods.model';
import { ITourNumberHotels } from 'src/lib/repository/schedule/schedule.model';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { environment } from '../../../environments/environment';
import { IBooking } from '../../../lib/repository/booking/booking.model';
import { IFlights } from '../../../lib/repository/flights/flights.model';
import {
  ICalendarInfo,
  ICalendarInfoDisplay,
} from '../../../lib/repository/front/calendar-info.model';
import { FrontApi } from '../../../lib/repository/front/front.api';
import { DialogService } from '../../services/dialog.service';
import { PackagegoodsDetailService } from '../../services/packagegoods-detail.service';
import { PackagegoodsStaticService } from '../../services/packagegoods-static.service';
import {
  BookingConfirmComponent,
  IBookingConfirmData,
  IBookingConfirmResult,
} from '../booking-confirm/booking-confirm.component';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';
import {
  HotelRoomSelectorComponent,
  IHotelRoomSelectorData,
} from '../hotel-room-selector/hotel-room-selector.component';
import {
  IRoomNumberSelectorData,
  IRoomNumberSelectorResult,
  RoomNumberSelectorComponent,
} from '../room-number-selector/room-number-selector.component';
import { FlightSelectorComponent } from './flight-selector/flight-selector.component';

export interface ModalBookingCalendarData {
  mrhst?: any;
  packagegoods: any;
  startDate?: string;
  returnDate?: string;
  tourNumber?: number;
  departingFlight?: any;
  returnFlight?: any;
  startPoint?: string;
  destinationPoint?: string;
  hotelMrhst?: IMrhst;
  hotelGoods?: IHotelGoods;
  isChange?: boolean;
  sglNumber?: number;
  twnNumber?: number;
  trpNumber?: number;
  qudNumber?: number;
  etcNumber?: number;
  year?: number;
  month?: number;
}

export interface ModalBookingCalendarResponse {
  mrhst?: any;
  packagegoods: any;
  startDate: string;
  returnDate: string;
  tourNumber: number;
  departingFlight: any;
  returnFlight: any;
  hotelGoods?: IHotelGoods;
  booking?: any;
  resetParams?: boolean;
}

export interface WeeksObject {
  0: boolean;
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
}

export interface DateInfo {
  infoMessage?: string;
  // 번역키가 들어가지 않는 원본값
  infoMessageOrig?: string;
  // 이용 가능 상품
  goodsIdList: number[];
  // departingFlights: any[];
  // returnFlights: any[];
  // tourNumber: number;
  // homePage: number;
  // merittour: number;
  // merittourDaegu: number;

  // 시설 사용하는 총 투어 인원
  activeTourNumberTotal: number;
  // 총 투어 인원
  tourNumberTotal: number;
  // 호텔 상품별 인원 정보
  [key: number]: ITourNumberHotels;

  // 총 룸 판매수
  roomNumberTotal: number;
}

export type CalendarMap = Map<string, DateInfo>;

@Component({
  selector: 'app-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
})
export class BookingCalendarComponent extends DialogAbstract implements OnInit {
  override dialogRef!: DialogRef<this, any, ModalBookingCalendarData>;

  onlineBookingCalendar$?: Observable<any>;

  canShow1 = true;

  canShow2 = true;

  yearList?: number[];

  #tourNumber?: number;

  calendarMap: CalendarMap = new Map();

  initDate1?: Date;

  initDate2?: Date;

  #startDate: string = null!;

  #returnDate: string = null!;

  dataBeforeChange: any;

  device$: Observable<string>;

  packagegoods?: IPackagegoods;

  // 이용 가능한 숙박 시설 목록
  availHotelMrhstList?: IMrhst[];

  // 선택한 숙박 시설 ID
  availHotelMrhstId = -1;

  // 호텔 상품 목록
  hotelGoodsList?: IHotelGoodsListDto[];

  // 선택한 호텔 상품 (룸 타입)
  selectedHotelGoods: IHotelGoods = null!;

  roomNumberInfo?: IRoomNumberSelectorResult;

  /**
   * @deprecated
   */
  tourNumberHotelList?: ITourNumberHotels[];

  calendarInfoList: ICalendarInfo[] = [];

  calendarInfoListSimple: ICalendarInfoDisplay[] = [];

  booking: IBooking | null = null;

  /**
   * 로딩 여부
   */
  isLoading = false;

  year1?: number;

  year2?: number;

  month1?: number;

  month2?: number;

  set tourNumber(value: number) {
    this.#tourNumber = value;
    // this.startDate = null;
    // this.returnDate = null;
  }

  get tourNumber(): number {
    return this.#tourNumber!;
  }

  get startDate(): string {
    return this.#startDate;
  }

  set startDate(str: string) {
    if (this.#startDate === str) {
      return;
    }

    this.#startDate = str;

    if (!str) {
      return;
    }

    //   if (this.brandService.brand.id === 2) {
    //     const message = `<span class="fs-5"><span class="text-danger fw-bold fs-4">귀국일을 선택하는 즉시 예약이 확정</span>됩니다.<br/>
    // 그대로 선택 하실 분은 <span class="text-danger fw-bold fs-4">확인버튼 클릭 후 귀국일</span>을 선택해 주세요.<br/><br/>
    // 출국일부터 일정을 새롭게 진행 원하시는 분은 취소버튼 클릭 후 다시 선택해주세요.</span>`;

    //     this.dialogService
    //       .confirm(message, null, {
    //         data: { negativeButton: { class: 'btn-danger' } },
    //       })
    //       .pipe(
    //         tap((result) => {
    //           if (!result) {
    //             this.reset();
    //           }
    //         }),
    //       )
    //       .subscribe();
    //   }

    // if (!this.calendarMap.get(str)) {
    //   return;
    // }

    // // if (this.calendarMap.get(str).departingFlights.length > 1) {
    // const callback = (flight: any): void => {
    //   if (flight) {
    //     this.departingFlight = flight;
    //   } else {
    //     this.startDate = null;
    //   }
    // };
    // this.openFlightSelectModal(
    //   this.calendarMap.get(str).departingFlights,
    //   callback,
    //   'ALERT.Input_Start_Flight',
    //   'Departure'
    // );
    // // } else {
    // //   this.departingFlight = this.calendarMap.get(this.startDate).departingFlights[0];
    // // }
  }

  get returnDate(): string {
    return this.#returnDate;
  }

  set returnDate(str: string) {
    if (this.#returnDate === str) {
      return;
    }

    this.#returnDate = str;

    // if (!str) {
    //   return;
    // }

    // if (!this.calendarMap.get(str)) {
    //   return;
    // }

    // // if (this.calendarMap.get(str).returnFlights.length > 1) {
    // const callback = (flight: any): void => {
    //   if (flight) {
    //     this.returnFlight = flight;
    //   } else {
    //     this.returnDate = null;
    //   }
    // };

    // this.openFlightSelectModal(
    //   this.calendarMap.get(str).returnFlights,
    //   callback,
    //   'ALERT.Input_Return_Flight',
    //   'Return'
    // );
    // // } else {
    // //   this.returnFlight = this.calendarMap.get(this.returnDate).returnFlights[0];
    // // }
  }

  @HostBinding('class.app')
  get isApp(): boolean {
    return Utils.isMobileSize();
  }

  get invalid(): boolean {
    return !this.startDate || !this.returnDate;
  }

  get isEwrc(): boolean {
    return this.homeScreenService.homeScreen.brandId === 2;
  }

  protected mrhstOnlyOne = false;

  /**
   * 선택한 기간의 룸 정보
   */
  private roomInfoByTerm: number[][] = [];

  private calendarInfoMapSimple: Map<string, ICalendarInfoDisplay> = new Map();

  private departingFlight: any = null;

  private returnFlight: any = null;

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    public roomBooking: HotelAssignApiService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private uiService: UiService,
    private loadingService: LoadingService,
    private hotelGoodsApiService: HotelGoodsApi,
    private bookingService: BookingService,
    private bookingApi: BookingApi,
    // private scheduleService: ScheduleApiService,
    private authService: AuthService,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
    private bookingDataService: BookingDataService,
    private packagegoodsDetailService: PackagegoodsDetailService,
    private shirosatoService: ShirosatoService,
    private frontApi: FrontApi,
    private packagegoodsStaticService: PackagegoodsStaticService,
  ) {
    super(elementRef);

    this.device$ = this.uiService.device$;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.dialogRef.data!.isChange) {
      this.dataBeforeChange = {
        startDate: this.dialogRef.data!.startDate,
        returnDate: this.dialogRef.data!.returnDate,
        tourNumber: this.dialogRef.data!.tourNumber,
      };
    }

    this.packagegoods = this.dialogRef.data!.packagegoods;
    this.tourNumber = this.dialogRef.data!.tourNumber!;
    this.selectedHotelGoods = this.dialogRef.data!.hotelGoods!;

    const mrhstList: IMrhst[] = Utils.getParsedJson(
      this.packagegoods!.availHotelMrhstListJson,
      [],
    );
    this.mrhstOnlyOne = mrhstList.length === 1;

    // 패키지 유효성 검사
    this.bookingDataService
      .validatePackagegoods(this.packagegoods!, this.tourNumber)
      .pipe(
        // 2024-06-11 메리트투어 남태희 상무님 요청으로 이바라키 시로사토 패키지 하드코딩
        // FIXME: 시로사토 하드코딩 제거
        // mergeMap((valid) => {
        //   if (!this.shirosatoService.isShirosato(this.packagegoods)) {
        //     // 시로사토 아니면 통과
        //     return of(true);
        //   }

        //   // 시로사토이면 두번의 검사 모두 유효해
        //   return this.shirosatoService.canBooking().pipe(
        //     map((canBooking) => {
        //       return valid && canBooking;
        //     }),
        //   );
        // }),
        tap((valid) => {
          if (!valid) {
            this.dialogRef.close();
            return;
          }

          // this.packagegoodsApiService
          //   .getAvailMrhstList(this.packagegoods!.id!, {
          //     goodsTypeList: ['HOTEL'],
          //   })
          //   .pipe(
          //     tap(({ hotelMrhstList }) => {
          //       this.availHotelMrhstList = hotelMrhstList;
          //     }),
          //   )
          //   .subscribe();

          if (this.dialogRef.data!.hotelGoods) {
            this.availHotelMrhstId = this.dialogRef.data!.hotelGoods.mrhst!.id!;
            this.getHotelGoods().subscribe();
          } else if (this.dialogRef.data!.hotelMrhst) {
            this.availHotelMrhstId = this.dialogRef.data!.hotelMrhst.id!;
            this.getHotelGoods().subscribe();
          } else {
            this.onHotelMrhstSelectButtonClick(true);
          }

          // this.availHotelMrhstId = this.availHotelMrhstList[0]?.id;
          this.calendarMap = new Map();
          this.setYearList();
          this.initDate();
          // this.makeMapOnChange();
        }),
      )
      .subscribe();
  }

  /**
   * 선택된 숙박 시설
   */
  getSelectedHotelMrhst(): IMrhst | undefined {
    return this.availHotelMrhstList?.find(
      (mrhst) => mrhst.id === this.availHotelMrhstId,
    );
  }

  /**
   * 숙박 변경 버튼
   */
  onHotelMrhstSelectButtonClick(skipIfOnlyOne = false): void {
    let mrhstResult = this.bookingDataService.openHotelMrhstSelect(
      this.packagegoods!,
      this.tourNumber,
      this.getSelectedHotelMrhst(),
      skipIfOnlyOne,
    );

    if (this.mrhstOnlyOne && skipIfOnlyOne) {
      const mrhstList: IMrhst[] = Utils.getParsedJson(
        this.packagegoods!.availHotelMrhstListJson,
        [],
      );
      mrhstResult = of([mrhstList[0], mrhstList]);
    }

    mrhstResult
      .pipe(
        tap(([mrhst, mrhstList]) => {
          this.availHotelMrhstList = mrhstList;

          if (mrhst) {
            this.availHotelMrhstId = mrhst.id!;
            this.getHotelGoods()
              .pipe(tap(() => this.makeMapOnChange()))
              .subscribe();

            this.startDate = null!;
            this.returnDate = null!;
            this.departingFlight = null;
            this.returnFlight = null;
            this.selectedHotelGoods = null!;

            if (this.booking?.id) {
              this.bookingApi
                .cancelBookingHomepage(this.booking.id, this.booking)
                .subscribe();
            }
          }
        }),
      )
      .subscribe();
  }

  /**
   * 숙박 시설 변경
   */
  onAvailHotelMrhstChange(event: Event): void {
    const element = <HTMLSelectElement>event.target;
    this.availHotelMrhstId = +element.value;

    // 호텔 상품 조회
    this.getHotelGoods()
      .pipe(tap(() => this.makeMapOnChange()))
      .subscribe();
  }

  onStartCalendarDateChange(date: Date): void {
    this.year1 = date.getFullYear();
    this.month1 = date.getMonth();

    // 한쪽 일자 변경되면 다른쪽도 변경
    const nextDate = new Date(this.year1, this.month1 + 1);
    this.year2 = nextDate.getFullYear();
    this.month2 = nextDate.getMonth();

    // 한쪽에서 끝쪽 일자를 선택하면 다른쪽은 안보이게 함, 다른쪽에 해당 연도가 없을수 있기때문
    if (
      this.month1 === 11 &&
      this.yearList![this.yearList!.length - 1] === this.year1
    ) {
      this.canShow2 = false;
    } else {
      this.canShow2 = true;
    }

    // 서버에서 roominfo 요청하여 맵 생성
    this.reset();
    //this.makeMapOnChange();
  }

  onEndCalendarDateChange(date: Date): void {
    this.year2 = date.getFullYear();
    this.month2 = date.getMonth();

    // 한쪽 일자 변경되면 다른쪽도 변경
    const nextDate = new Date(this.year2, this.month2 - 1);
    this.year1 = nextDate.getFullYear();
    this.month1 = nextDate.getMonth();

    // 한쪽에서 끝쪽 일자를 선택하면 다른쪽은 안보이게 함, 다른쪽에 해당 연도가 없을수 있기때문
    if (this.month2 === 0 && this.yearList![0] === this.year2) {
      this.canShow1 = false;
    } else {
      this.canShow1 = true;
    }

    // 서버에서 roominfo 요청하여 맵 생성
    this.makeMapOnChange();
  }

  /**
   * 일자 선택 후 동작
   */
  onDateSeleted([startDate, returnDate]: [string, string]): void {
    this.setFlightViaDialog();

    const startIndex = +startDate.split('-').reverse()[0] - 1;
    const endIndex = +returnDate.split('-').reverse()[0] - 1;

    this.roomInfoByTerm = Array.from(this.calendarMap.values())
      .slice(startIndex, endIndex)
      .map(({ goodsIdList }) => goodsIdList);
    this.selectedHotelGoods = this.getAvailableHotelGoods(
      this.hotelGoodsList!,
      this.roomInfoByTerm,
    )!;

    this.onOkButtonClick();
  }

  private openHotelRoomSelector(): Observable<IHotelGoods> {
    const nights = dayjs(this.returnDate).diff(dayjs(this.startDate), 'day');

    // this.selectedHotelGoods = this.hotelGoodsList?.find(
    //   (goods) => goods.id === this.booking?.selectedGoodsId,
    // )!;

    let filteredHotelGoodsList = [...this.hotelGoodsList!];
    this.roomInfoByTerm.forEach((info) => {
      filteredHotelGoodsList = filteredHotelGoodsList.filter((goods) =>
        info?.includes(goods.id!),
      );
    });

    if (this.homeScreenService.homeScreen.sellSortForwardOnlyFlg) {
      const firstSellSort = filteredHotelGoodsList[0].sellSort;
      const selectableGoodsList = filteredHotelGoodsList.filter(
        ({ sellSort }) => firstSellSort === sellSort,
      );

      if (selectableGoodsList.length === 1) {
        return of(selectableGoodsList[0]);
      }
    }

    return this.dialogService.open(
      HotelRoomSelectorComponent,
      <IHotelRoomSelectorData>{
        goodsList: this.hotelGoodsList,
        calendarInfoList: this.roomInfoByTerm,
        selected: this.selectedHotelGoods,
        tourNumber: this.tourNumber,
        nights,
      },
      {
        backdrop: 'static',
      },
    ).onDialogClosed;
  }

  /**
   * 객실 타입별 개수 선택 창 열기
   */
  private openRoomNumberSelector(
    hotelGoods: IHotelGoods,
  ): Observable<IRoomNumberSelectorResult | undefined> {
    const data: IRoomNumberSelectorData = {
      hotelGoods,
      tourNumber: this.tourNumber,
    };

    const bootstrapDialogConfig = {
      maxWidth: '80vw',
      maxHeight: '80vh',
      disableClose: true,
    };

    return this.dialogService.open<
      RoomNumberSelectorComponent,
      IRoomNumberSelectorResult,
      IRoomNumberSelectorData
    >(RoomNumberSelectorComponent, data, bootstrapDialogConfig).onDialogClosed;
  }

  private reset(): void {
    this.startDate = null!;
    this.returnDate = null!;
    this.departingFlight = null;
    this.returnFlight = null;
    this.selectedHotelGoods = null!;
    this.roomInfoByTerm = null!;
    this.roomNumberInfo = null!;

    this.makeMapOnChange();
  }

  onAnotherDayClick(ignoreMessage = false): void {
    if (this.booking?.id) {
      this.bookingApi
        .cancelBookingHomepage(this.booking.id, this.booking)
        .pipe(
          tap(() => {
            this.booking = null;
          }),
        )
        .subscribe();
    }

    if (ignoreMessage) {
      this.reset();
      return;
    }

    if (this.brandService.brand.id === 2) {
      // this.dialogService
      //   .alert('ALERT.beforeBookingNotice')
      //   .pipe(
      //     tap(() => {
      //     }),
      //   )
      //   .subscribe();
      this.reset();
    } else {
      this.reset();
    }
  }

  onOkButtonClick(): void {
    if (this.invalid) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    const startDt = dayjs(this.startDate);

    let canBooking$ = of(true);

    if (
      !(
        startDt.isAfter(environment.booking2025From) &&
        startDt.isBefore(environment.booking2025To)
      )
    ) {
      if (this.shirosatoService.isShirosato(this.packagegoods)) {
        canBooking$ = this.shirosatoService.canBooking();
      }
    }

    let filteredHotelGoodsList = [...(this.hotelGoodsList ?? [])];
    this.roomInfoByTerm.forEach((info) => {
      filteredHotelGoodsList = filteredHotelGoodsList.filter((goods) =>
        info?.includes(goods.id!),
      );
    });

    const firstSellSort = filteredHotelGoodsList[0]?.sellSort;
    filteredHotelGoodsList = filteredHotelGoodsList.filter(
      ({ sellSort }) => firstSellSort === sellSort,
    );

    // 상품 선택 가능여부
    const canSelectGoods = filteredHotelGoodsList.length > 1;

    canBooking$
      .pipe(
        filter((canBooking) => canBooking),
        // tap(() => {
        //   if (!canSelectGoods) {
        //     this.isLoading = true;
        //     this.loadingService.start();
        //   }
        // }),
        // 예약 생성
        // mergeMap(() => {
        //   if (canSelectGoods) {
        //     return of(null);
        //   }
        //   return this.createTempBooking$();
        // }),
        // 객실 선택 다이얼로그
        mergeMap(() => this.openHotelRoomSelector()),
        // 확인 버튼 누르지 않고 닫았다면 종료
        filter((hotelGoods) => !!hotelGoods),
        mergeMap((hotelGoods) => {
          //   if (!canSelectGoods) {
          //     return of(hotelGoods);
          //   }

          //   if (!hotelGoods) {
          //     this.reset();
          //     return EMPTY;
          //   }

          //   this.selectedHotelGoods = hotelGoods;

          //   this.isLoading = true;
          //   this.loadingService.start();

          //   return this.createTempBooking$().pipe(map(() => hotelGoods));
          // }),
          // mergeMap((hotelGoods) => {
          //   if (!hotelGoods) {
          //     return this.bookingApi
          //       .cancelBookingHomepage(this.booking!.id!, this.booking!)
          //       .pipe(
          //         mergeMap(() => {
          //           this.reset();
          //           return EMPTY;
          //         }),
          //       );
          //   }

          this.selectedHotelGoods = hotelGoods;

          // 룸타입별 개수
          return this.getRoomNumber$(hotelGoods);
        }),
        tap((roomNumber) => {
          // 룸타입별 개수 저장
          this.roomNumberInfo = roomNumber;
          // 예약 파라미터
          // this.booking = {
          //   ...this.booking,
          //   ...this.roomNumberInfo,
          //   selectedGoodsId: this.selectedHotelGoods.id,
          //   selectedMrhstId: this.selectedHotelGoods.mrhst?.id,
          // };
        }),
        // 메시지 확인
        // FIXME: 임시 하드코딩
        mergeMap(() => this.isSpecialDayOk()),
        // 메시지 있으면 종료, 없으면(true이면) 통과
        // mergeMap((ok) => {
        //   if (!ok) {
        //     return this.bookingApi
        //       .cancelBookingHomepage(this.booking!.id!, this.booking!)
        //       .pipe(
        //         mergeMap(() => {
        //           this.reset();
        //           return EMPTY;
        //         }),
        //       );
        //   }

        //   return of(ok);
        // }),
        // 로딩 시작
        // tap(() => {
        //   this.isLoading = true;
        //   this.loadingService.start();
        // }),
        // 예약 숙박 상품 갱신
        // mergeMap(() =>
        //   this.scheduleService.updateHotel(this.booking.id, this.booking),
        // ),
        tap(() => {
          // 출발지, 목적지 인원 일정 박 수 나오고 진행하시겠습니까?
          const startName = this.departingFlight.startName;
          const destinationName = this.departingFlight.destinationName;

          const nights = dayjs(this.returnDate).diff(
            dayjs(this.startDate),
            'day',
          );

          this.dialogService
            .open(
              BookingConfirmComponent,
              <IBookingConfirmData>{
                startName,
                destinationName,
                tourNumber: this.tourNumber,
                nights,
                hotelGoods: this.selectedHotelGoods,
                startDate: this.startDate,
                returnDate: this.returnDate,
                packagegoods: this.packagegoods,
                mrhst: this.getSelectedHotelMrhst(),
              },
              {
                backdrop: 'static',
              },
            )
            .onDialogClosed.pipe(
              filter((answer: IBookingConfirmResult) => answer.result),
              // 로딩 시작
              tap(() => {
                this.isLoading = true;
                this.loadingService.start();
              }),
              // mergeMap((answer: IBookingConfirmResult) => {
              //   if (!answer.result) {
              //     return this.bookingApi
              //       .cancelBookingHomepage(this.booking!.id!, this.booking!)
              //       .pipe(
              //         mergeMap(() => {
              //           this.reset();
              //           return EMPTY;
              //         }),
              //       );
              //   }

              //   return of(answer.result);
              // }),
              // 예약 생성
              mergeMap(() => {
                return this.createTempBooking$();
              }),
              catchError((e: Error) => {
                this.startDate = null!;
                this.returnDate = null!;

                this.isLoading = false;
                this.loadingService.stop();

                let message = '';

                if (e instanceof HttpErrorResponse) {
                  if (e?.error?.message) {
                    message = e.error.message;
                  }
                } else if (e?.message) {
                  message = e.message;
                }

                if (message) {
                  return this.dialogService.alert(message).pipe(
                    tap(() => {
                      this.onAnotherDayClick();
                    }),
                    mergeMap(() => EMPTY),
                  );
                }

                return throwError(() => e);
              }),
              tap(() => {
                // 다이얼로그 종료
                this.dialogRef.close({
                  startDate: this.startDate,
                  returnDate: this.returnDate,
                  tourNumber: this.tourNumber,
                  packagegoods: this.packagegoods,
                  departingFlight: this.departingFlight,
                  returnFlight: this.returnFlight,
                  hotelGoods: this.selectedHotelGoods,
                  booking: this.booking,
                });
              }),
              finalize(() => {
                this.isLoading = false;
                this.loadingService.stop();
              }),
            )
            .subscribe();
        }),
        catchError((e: Error) => {
          this.startDate = null!;
          this.returnDate = null!;

          this.isLoading = false;
          this.loadingService.stop();

          let message = '';

          if (e instanceof HttpErrorResponse) {
            if (e?.error?.message) {
              message = e.error.message;
            }
          } else if (e?.message) {
            message = e.message;
          }

          if (message) {
            return this.dialogService.alert(message).pipe(
              tap(() => {
                this.onAnotherDayClick();
              }),
            );
          }

          return throwError(() => e);
        }),
        // 로딩 종료
        finalize(() => {
          this.isLoading = false;
          this.loadingService.stop();
        }),
        // 로딩 종료
        // finalize(() => {
        //   this.isLoading = false;
        //   this.loadingService.stop();
        // }),
      )
      .subscribe();
  }

  /**
   * 창 닫기
   *
   * 진행 중 예약 있으면 취소 확인 후 닫기
   */
  onCancelClick(): void {
    if (this.booking?.id) {
      this.dialogService
        .confirm('CONFIRM.CancelSimple', null, {
          data: {
            positiveButton: { text: 'yes' },
            negativeButton: { text: 'no' },
          },
        })
        .pipe(
          mergeMap((res) => {
            if (res) {
              return this.bookingApi.cancelBookingHomepage(
                this.booking!.id!,
                this.booking!,
              );
            }

            return EMPTY;
          }),
          tap(() => {
            this.dialogRef.close({ resetParams: true });
          }),
        )
        .subscribe();
    } else {
      this.dialogRef.close(null);
    }
  }

  onArrowButtonClick(n: number): void {
    const targetDate = new Date(this.year1!, this.month1! + n);
    const nextDate = new Date(this.year1!, this.month1! + n + 1);
    this.onStartCalendarDateChange(targetDate);
    this.initDate1 = targetDate;
    this.initDate2 = nextDate;

    if (this.month2 === 0 && this.yearList![0] === this.year2) {
      this.canShow1 = false;
    } else {
      this.canShow1 = true;
    }
  }

  /**
   * 가예약 생성
   */
  private createTempBooking$(): Observable<any> {
    if (!this.selectedHotelGoods) {
      this.loadingService.stop();
      // 숙박 상품 없음
      return this.dialogService.alert('ALERT.Booking_Excess_Tour_Number').pipe(
        tap(() => this.onAnotherDayClick()),
        mergeMap(() => EMPTY),
      );
    }

    const params = {
      ...this.bookingService.getBookingBody(
        this.startDate,
        this.returnDate,
        this.tourNumber,
        this.packagegoods!,
        this.authService.account?.userInfo,
        this.departingFlight,
        this.returnFlight,
        this.selectedHotelGoods,
        this.dialogRef.data!.sglNumber,
        this.dialogRef.data!.twnNumber,
        this.dialogRef.data!.trpNumber,
        this.dialogRef.data!.qudNumber,
        this.dialogRef.data!.etcNumber,
        this.hotelGoodsList?.map((goods) => {
          return { id: goods.id, goodsNm: goods.goodsNm };
        }),
      ),
      ...this.roomNumberInfo,
    };

    return this.bookingApi.create(params).pipe(
      tap((res) => {
        this.booking = res;
      }),
      // catchError((e: Error) => {
      //   this.isLoading = false;
      //   this.loadingService.stop();

      //   if (e instanceof HttpErrorResponse) {
      //     if (e?.error?.message) {
      //       return this.dialogService.alert(e.error.message);
      //     }
      //   } else if (e?.message) {
      //     return this.dialogService.alert(e.message);
      //   }

      //   return EMPTY;
      // }),
      // 로딩 종료
      finalize(() => {
        this.isLoading = false;
        this.loadingService.stop();
      }),
    );
  }

  /**
   * 선택한 시설의 호텔 상품 조회
   */
  private getHotelGoods(): Observable<IHotelGoods[]> {
    if (this.availHotelMrhstId <= 0) {
      return of([]);
    }

    this.loadingService.start();

    return this.hotelGoodsApiService
      .getHomepageBookingList({
        mrhstId: this.availHotelMrhstId,
      })
      .pipe(
        tap((list) => {
          this.hotelGoodsList = list;
          this.loadingService.stop();

          // 시설에 이용 가능한 상품 없으면 종료
          if (!list?.length) {
            this.showNoAvailGoodsAlertAndClose();
          }
        }),
        catchError((e) => {
          this.loadingService.stop();
          return throwError(() => e);
        }),
      );
  }

  /**
   * 우선 선택 가능한 상품 목록
   */
  private getFirstAvailableHotelGoodsList(): IHotelGoods[] {
    // 상품 없음
    if (!this.hotelGoodsList?.length) {
      return [];
    }

    // 판매순서대로 정렬
    const sortedList = this.hotelGoodsList.sort(
      (a, b) => (a?.sellSort ?? 0) - (b?.sellSort ?? 0),
    );

    // 마지막 순위, 초기값은 첫 순위
    let lastSort = sortedList[0].sellSort;

    const firstAvailableList: IHotelGoods[] = [];

    sortedList.forEach((goods) => {
      // 후순위라면 다음으로
      if (lastSort !== goods.sellSort) {
        lastSort = goods.sellSort;
        return;
      }

      // 마지막이면 저장
      firstAvailableList.push(goods);
    });

    return firstAvailableList;
  }

  /**
   * 기본 데이터 생성
   */
  private getDefaultInfoMap(
    fromDate: string,
    toDate: string,
  ): Map<string, ITourNumberHotels[]> {
    const count = dayjs(toDate).diff(fromDate, 'days') + 1;
    const availableHotelGoods = this.getFirstAvailableHotelGoodsList();
    let date = dayjs(fromDate);
    const dataMap = new Map();

    const list = Array(count)
      .fill(null)
      .map(() => {
        const data = {
          date: date.format('YYYY-MM-DD'),
          goodsId: availableHotelGoods[0].id,
          goodsName: availableHotelGoods[0].goodsNm,
          activeTourNumberTotal: 0,
          tourNumberTotal: 0,
          capacity: availableHotelGoods[0].capacity,
        };

        date = date.add(1, 'day');

        return data;
      });

    list.forEach((info) => {
      dataMap.set(info.date, [info]);
    });

    return dataMap;
  }

  /**
   * 기본 데이터 생성
   */
  private getDefaultInfoMapNew(
    fromDate: string,
    toDate: string,
  ): Map<string, ICalendarInfoDisplay> {
    const count = dayjs(toDate).diff(fromDate, 'days') + 1;
    let date = dayjs(fromDate);
    const dataMap = new Map<string, ICalendarInfoDisplay>();

    const list = Array(count)
      .fill(null)
      .map(() => {
        const formatted = date.format('YYYY-MM-DD');

        date = date.add(1, 'day');

        return formatted;
      });

    list.forEach((dateString) => {
      dataMap.set(dateString, {
        date: dateString,
        mrhstId: 0,
        goodsId: null,
        goodsNm: null,
        activeTourNumberTotal: 0,
        tourNumberTotal: 0,
      });
    });

    return dataMap;
  }

  private setCalendarMapNew(calendarInfoList: ICalendarInfo[]): void {
    this.calendarInfoList = calendarInfoList;
    this.calendarMap.clear();

    // 이용 가능 여부에 따른 일자별 메시지 설정
    this.calendarInfoList.forEach((info) => {
      this.calendarMap.set(info.date!, {
        // 이용 가능한 시설을 설정
        infoMessage: info.goodsName,
        infoMessageOrig: info.goodsName,
        goodsIdList: info.goodsIdList,
        activeTourNumberTotal: info.activeTourNumberTotal,
        tourNumberTotal: info.tourNumberTotal,
        roomNumberTotal: 0,
      });
    });
  }

  private setCalendarMapNewSimple(
    calendarInfoList: ICalendarInfoDisplay[],
  ): void {
    this.calendarInfoListSimple = calendarInfoList;
    this.calendarMap.clear();

    // 시작일 설정
    let fromDate = new DatePipe('en').transform(
      new Date(this.year1!, this.month1!),
      'yyyy-MM-dd',
    )!;

    if (dayjs(fromDate).diff(this.startDate, 'days') > 0) {
      fromDate = this.startDate;
    }

    // 종료일 설정
    let toDate = new DatePipe('en').transform(
      new Date(this.year2!, this.month2!, 7),
      'yyyy-MM-dd',
    )!;

    if (dayjs(toDate).diff(this.returnDate, 'days') < 0) {
      toDate = this.returnDate;
    }

    // 초기 설정 (시작 ~ 종료일 까지의 빈 값)
    this.calendarInfoMapSimple = this.getDefaultInfoMapNew(fromDate, toDate);

    // 일자별로 통계 데이터 삽입
    this.calendarInfoMapSimple.forEach((_, date) => {
      calendarInfoList.forEach((info) => {
        if (info.date === date) {
          this.calendarInfoMapSimple.set(date, info);
        }
      });
    });

    // 이용 가능 여부에 따른 일자별 메시지 설정
    this.calendarInfoMapSimple.forEach((info, date) => {
      const goodsList = this.getAvailableHotelGoodsSimple(
        info,
        this.hotelGoodsList!,
      );

      const goodsNm =
        (this.homeScreenService.homeScreen.sellSortForwardOnlyFlg
          ? goodsList[0]?.goodsNm
          : goodsList.map((goods) => goods.goodsNm).join('\n')) || '마감';

      this.calendarMap.set(date, {
        // 이용 가능한 시설을 설정
        infoMessage: goodsNm,
        infoMessageOrig: goodsNm,
        goodsIdList: goodsList.map(({ id }) => id!),
        activeTourNumberTotal: info?.activeTourNumberTotal ?? 0,
        tourNumberTotal: info?.tourNumberTotal ?? 0,
        roomNumberTotal: 0,
      });
    });
  }

  /**
   * 일자별 예약 가능 인원 맵 생성
   * @deprecated
   */
  private setCalendarMap(tourNumberHotelList: ITourNumberHotels[]): void {
    this.tourNumberHotelList = tourNumberHotelList;
    this.calendarMap.clear();

    // 시작일 설정
    let fromDate = new DatePipe('en').transform(
      new Date(this.year1!, this.month1!),
      'yyyy-MM-dd',
    )!;

    if (dayjs(fromDate).diff(this.startDate, 'days') > 0) {
      fromDate = this.startDate;
    }

    // 종료일 설정
    let toDate = new DatePipe('en').transform(
      new Date(this.year2!, this.month2! + 1, 0),
      'yyyy-MM-dd',
    )!;

    if (dayjs(toDate).diff(this.returnDate, 'days') < 0) {
      toDate = this.returnDate;
    }

    // 초기 설정 (시작 ~ 종료일 까지의 빈 값)
    const dateMap = this.getDefaultInfoMap(fromDate, toDate);

    // 일자별로 통계 데이터 삽입
    dateMap.forEach((dataList, date) => {
      const dataList1: ITourNumberHotels[] = [];
      tourNumberHotelList.forEach((tourNumberHotel) => {
        if (tourNumberHotel.date === date) {
          dataList1.push(tourNumberHotel);
        }
      });

      if (dataList1.length) {
        dataList.splice(0, dataList.length);
        dataList.push(...dataList1);
      }
    });

    // FIXME: 개선, 방식 변경 (서버 메시지 그대로 표시)

    // 일자별 사용 가능한 시설
    const availableMap = new Map<string, Record<number, boolean>>();

    // 일자별 시설 이용 수
    const capacityMap = new Map<string, Record<number, number>>();

    // 일자별 숙박상품별 숙박 가능 인원 설정
    dateMap.forEach((infoListByDate, date) => {
      let totalCapacity = 0;
      let totalRoomNumber = 0;
      // 호텔 상품별 조회
      this.hotelGoodsList!.forEach((goods) => {
        // 상품 정보
        const {
          id,
          capacity: nullableCapacity,
          sellCapacity: nullableSellCapacity,
          mrhstId,
        } = goods;

        // 시설 정보
        const {
          mrhstCapacity: nullableMrhstCapacity,
          mrhstSellCapacity: nullableMrhstSellCapacity,
        } = this.packagegoodsDetailService.mrhstMap.get(mrhstId!)!;

        // 재고 설정 안했으면 무제한
        const goodsCapacity = isNil(nullableCapacity)
          ? Infinity
          : nullableCapacity;
        const goodsSellCapacity = isNil(nullableSellCapacity)
          ? Infinity
          : nullableSellCapacity;
        const mrhstCapacity = isNil(nullableMrhstCapacity)
          ? Infinity
          : nullableMrhstCapacity;
        const mrhstSellCapacity = isNil(nullableMrhstSellCapacity)
          ? Infinity
          : nullableMrhstSellCapacity;

        // 상품별 통계 조회
        const numberInfo = infoListByDate.find((info) => info.goodsId === id);

        // 통계 있으면
        if (numberInfo) {
          // 예약된 룸 갯수
          const totalRoom =
            (numberInfo.sglNumber ?? 0) +
            (numberInfo.twnNumber ?? 0) +
            (numberInfo.trpNumber ?? 0) +
            (numberInfo.quaNumber ?? 0) +
            (numberInfo.etcNumber ?? 0);

          // 총 예약 인원 및 룸 합산
          totalCapacity += numberInfo.tourNumberTotal;
          totalRoomNumber += totalRoom;

          // 상품 최대 판매 수보다 작으면 예약 가능
          const roomAvailable = totalRoom < goodsSellCapacity;

          // 시설 수용 가능 여부
          const totalCapacityAvailable =
            totalCapacity + this.tourNumber <= mrhstCapacity;

          // 시설 판매 가능 여부
          const totalRoomAvailable = totalRoomNumber <= mrhstSellCapacity;

          // (총 인원 - 수용 가능 인원 + 예약할 인원)
          const capacity =
            numberInfo.capacity -
            (numberInfo.tourNumberTotal + this.tourNumber);
          // 0 같거나 넘으면 예약 가능
          const capacityAvailable = capacity >= 0;

          // 예약 가능 여부 저장
          availableMap.set(date, {
            ...availableMap.get(date),
            [id!]:
              capacityAvailable &&
              roomAvailable &&
              totalCapacityAvailable &&
              totalRoomAvailable,
          });

          // 남은 수용 가능 인원수 저장
          capacityMap.set(date, {
            ...capacityMap.get(date),
            [id!]: capacity,
          });
        } else {
          // 정보 없으면 상품의 기본 정보 사용 (상품 남은 수용 가능 인원 - 예약할 인원)
          const capacity = goodsCapacity - this.tourNumber;

          // 0 같거나 넘으면 예약 가능
          const capacityAvailable = capacity >= 0;

          // 예약 가능 여부 저장
          availableMap.set(date, {
            ...availableMap.get(date),
            [id!]: capacityAvailable,
          });

          // 남은 수용 가능 인원수 저장
          capacityMap.set(date, {
            ...capacityMap.get(date),
            [id!]: capacity,
          });
        }
      });
    });

    // 숙박 상품 판매 순서대로만 예약 가능
    if (this.homeScreenService.homeScreen.sellSortForwardOnlyFlg) {
      // 숙박 가능 인원에 따른 일자별 숙박상품별 이용 가능 여부 설정
      capacityMap.forEach((v, date) => {
        if (v) {
          const capacityEntries = Object.entries(v);

          for (let i = 0; i < capacityEntries.length; i += 1) {
            const [goodsId] = capacityEntries[i];
            const goods = this.hotelGoodsList!.find(
              ({ id }) => id === +goodsId,
            );

            // 지금 반복에서 검사중인 상품보다 판매 순위 낮은 상품
            const list1 = capacityEntries.slice(i + 1).filter(
              ([id1]) =>
                // 동일 판매 순서는 제외
                goods!.sellSort !==
                this.hotelGoodsList!.find(({ id }) => id === +id1)!.sellSort,
            );

            // 판매된 순위 낮은 상품이 있는지 검사
            const isNoHasCapacity = list1.every(([goodsId1, capacity1]) => {
              const otherGoods = this.hotelGoodsList!.find(
                ({ id }) => id === +goodsId1,
              );

              // 상품의 최대 수용 인원과 예약 가능 인원이 동일하면 판매되지 않은것
              return capacity1 + this.tourNumber === otherGoods!.capacity;
            });

            // 이후 판매 상품 하나라도 판매됐으면 이용 불가 처리
            if (!isNoHasCapacity) {
              // 이용 불가 처리
              const availableData = availableMap.get(date);

              availableMap.set(date, {
                ...availableData,
                [goods!.id!]: false,
              });
            }
          }
        }
      });
    }

    // 이용 가능 여부에 따른 일자별 메시지 설정
    dateMap.forEach((infoListByDate) => {
      infoListByDate.forEach((info) => {
        const exist = this.calendarMap.get(info.date!);

        // capacity
        // 이용 가능한 시설 이름 조회
        const message = this.getAvailableHotelName(
          availableMap.get(info.date!)!,
          this.hotelGoodsList!,
        );

        // 예약한 룸 합계
        const roomTotal =
          info.sglNumber! +
          info.twnNumber! +
          info.trpNumber! +
          info.quaNumber! +
          info.etcNumber!;

        this.calendarMap.set(info.date!, {
          // 이용 가능한 시설을 설정
          infoMessage: message,
          infoMessageOrig: message,
          goodsIdList: [],
          activeTourNumberTotal: Math.max(
            (exist?.activeTourNumberTotal ?? 0) + info.activeTourNumberTotal,
            0,
          ),
          tourNumberTotal: Math.max(
            (exist?.tourNumberTotal ?? 0) + info.tourNumberTotal,
            0,
          ),
          roomNumberTotal: (exist?.roomNumberTotal ?? 0) + roomTotal,
        });

        const { roomNumberTotal } = this.calendarMap.get(info.date!)!;

        // 예약한 룸 개수가 총 판매 개수 초과 시 마감 처리
        if (
          info.mrhstSellCapacity != null &&
          roomNumberTotal >= info.mrhstSellCapacity
        ) {
          this.calendarMap.set(info.date!, {
            ...this.calendarMap.get(info.date!)!,
            infoMessage: '마감',
            infoMessageOrig: '마감',
          });
        }

        // 해당 시설을 예약한 총 인원
        const tourNumberTotal = this.tourNumberHotelList!.filter(
          (tourNumberHotel) => tourNumberHotel.date === info.date,
        ).reduce((prev, cur) => prev + cur?.tourNumberTotal || 0, 0);

        if (
          // 시설에 최대 수용 인원이 설정되어있고
          !!info.mrhstCapacity &&
          // (예약한 인원 + 지금 예약하려는 인원)이 최대 수용인원보다 많으면
          tourNumberTotal + this.tourNumber > info.mrhstCapacity
        ) {
          // 마감 처리
          this.calendarMap.set(info.date!, {
            ...this.calendarMap.get(info.date!)!,
            infoMessage: '마감',
            infoMessageOrig: '마감',
          });
        }
      });
    });
  }

  private makeMapOnChange(): void {
    if (this.availHotelMrhstId <= 0) {
      return;
    }

    const params = {
      startDate: new DatePipe('en').transform(
        new Date(this.year1!, this.month1!),
        'yyyy-MM-dd',
      ),
      returnDate: new DatePipe('en').transform(
        new Date(this.year2!, this.month2!, 7),
        'yyyy-MM-dd',
      ),
      brandId: this.dialogRef.data!.packagegoods?.brandId,
      // mrhstId: this.availHotelMrhstId,
      packagegoodsId: this.dialogRef.data!.packagegoods?.id,
      tourNumber: this.tourNumber,
      sellSortForwardOnlyFlg:
        this.homeScreenService.homeScreen.sellSortForwardOnlyFlg,
      // 호텔 상품 목록
      hotelGoodsListJson: Utils.getStringifiedJson(
        this.hotelGoodsList?.map((goods) => {
          return {
            id: goods.id,
            goodsNm: goods.goodsNm,
            capacity: goods.capacity,
            sellCapacity: goods.sellCapacity,
            sellSort: goods.sellSort,
          } as IHotelGoods;
        }),
      ),
    };

    if (dayjs(params.startDate).diff(this.startDate, 'days') > 0) {
      params.startDate = this.startDate;
    }

    if (dayjs(params.returnDate).diff(this.returnDate, 'days') < 0) {
      params.returnDate = this.returnDate;
    }

    this.loadingService.start();

    // const now = dayjs();

    if (
      // (now.isAfter(environment.season1From) &&
      // now.isBefore(environment.season3To)) ||
      !this.packagegoods?.createBookingScheduleFlg
    ) {
      {
        this.onlineBookingCalendar$ = this.frontApi
          .getRoomInfoList({
            ...params,
          })
          .pipe(
            tap((response) => {
              this.setCalendarMapNewSimple(response);
            }),
            shareReplay(1),
            finalize(() => {
              this.loadingService.stop();
            }),
          );
      }
    } else {
      this.onlineBookingCalendar$ = this.frontApi
        .getCalendarInfoList({
          ...params,
          mrhstId: this.availHotelMrhstId,
        })
        .pipe(
          tap((response) => {
            this.setCalendarMapNew(response);
          }),
          shareReplay(1),
          finalize(() => {
            this.loadingService.stop();
          }),
        );
    }
  }

  // 올해, 올해+1 연도 생성
  private setYearList(): void {
    this.yearList = [];
    const thisYear = new Date().getFullYear();

    for (let i = 0; i < 2; i += 1) {
      this.yearList.push(thisYear + i);
    }
  }

  private openFlightSelectModal(
    flights: any[],
    callback: (f: any) => void,
    title = 'ALERT.Input_Flight',
    titlePrefix?: string,
  ): void {
    if (!flights.length) {
      callback(null);
      return;
    }

    if (flights.length === 1) {
      callback(flights[0]);
      return;
    }

    this.dialogService
      .open(
        FlightSelectorComponent,
        { flights, title, titlePrefix },
        {
          // panelClass: this.isApp ? null : 'flight-selector',
          // maxWidth: '100vw',
          // maxHeight: '100vh',
        },
      )
      .onDialogClosed.pipe(
        tap((f) => {
          callback(f);
        }),
      )
      .subscribe();
  }

  private setFlightViaDialog(): void {
    const { departingFlightsJson, returnFlightsJson } = this.packagegoods!;
    const departingFlights: IFlights[] = Utils.getParsedJson(
      departingFlightsJson!,
      [],
    );
    const returnFlights: IFlights[] = Utils.getParsedJson(
      returnFlightsJson!,
      [],
    );

    const filteredDepartingFlights = departingFlights.filter(
      ({ startPoint, destinationPoint }: IFlights) => {
        return (
          startPoint!.indexOf(this.dialogRef.data!.startPoint!) >= 0 &&
          destinationPoint!.indexOf(this.dialogRef.data!.destinationPoint!) >= 0
        );
      },
    );

    const filteredReturnFlights = returnFlights.filter(
      ({ startPoint, destinationPoint }: IFlights) => {
        return (
          startPoint!.indexOf(this.dialogRef.data!.destinationPoint!) >= 0 &&
          destinationPoint!.indexOf(this.dialogRef.data!.startPoint!) >= 0
        );
      },
    );

    this.openFlightSelectModal(
      filteredDepartingFlights,
      (df) => {
        if (!df) {
          return;
        }

        this.departingFlight = df;

        this.openFlightSelectModal(
          filteredReturnFlights,
          (rf) => {
            if (!rf) {
              return;
            }

            this.returnFlight = rf;
          },
          'ALERT.Input_Return_Flight',
          '귀국',
        );
      },
      'ALERT.Input_Start_Flight',
      '출국',
    );
  }

  private initDate(): void {
    this.departingFlight = this.dialogRef.data!.departingFlight
      ? this.dialogRef.data!.departingFlight
      : null;
    this.returnFlight = this.dialogRef.data!.returnFlight
      ? this.dialogRef.data!.returnFlight
      : null;

    if (this.dialogRef.data!.returnDate) {
      this.startDate = this.dialogRef.data!.startDate!;
      this.returnDate = this.dialogRef.data!.returnDate!;

      if (!this.isApp) {
        this.initDate2 = new Date(this.returnDate);
        this.initDate1 = new Date(
          this.initDate2.getFullYear(),
          this.initDate2.getMonth() - 1,
        );
      } else {
        this.initDate1 = new Date(this.startDate);
        this.initDate2 = new Date(
          this.initDate1.getFullYear(),
          this.initDate1.getMonth() + 1,
        );
      }
    } else if (
      this.dialogRef.data!.year &&
      this.dialogRef.data!.month !== undefined
    ) {
      this.initDate1 = new Date(
        this.dialogRef.data!.year,
        this.dialogRef.data!.month,
      );
      this.initDate2 = new Date(
        this.initDate1.getFullYear(),
        this.initDate1.getMonth() + 1,
      );
    } else {
      // 오늘
      const todayMs = dayjs().valueOf();
      // 출발 가능 시작일
      const startDtFromMs = dayjs(
        this.dialogRef.data!.packagegoods.startDtFrom || undefined,
      ).valueOf();
      // 오늘로부터 예약 가능한 첫번째 날
      const bookingDaysUntilStartMs = dayjs()
        .add(
          this.dialogRef.data!.packagegoods.bookingDaysUntilStart || 0,
          'day',
        )
        .valueOf();
      // 상기중 더 미래
      const latestDayMs = Math.max(
        todayMs,
        startDtFromMs,
        bookingDaysUntilStartMs,
      );
      this.initDate1 = new Date(latestDayMs);
      this.initDate2 = new Date(
        this.initDate1.getFullYear(),
        this.initDate1.getMonth() + 1,
      );
    }

    this.year1 = this.initDate1.getFullYear();
    this.month1 = this.initDate1.getMonth();
    this.year2 = this.initDate2.getFullYear();
    this.month2 = this.initDate2.getMonth();
  }

  /**
   * 룸타입별 개수 옵저버블 반환
   */
  private getRoomNumber$(
    hotelGoods: IHotelGoods,
  ): Observable<IRoomNumberSelectorResult | undefined> {
    const {
      roomTypeByTourNumberFlg,
      roomTypeSglFlg,
      roomTypeTwnFlg,
      roomTypeTrpFlg,
      roomTypeQudFlg,
      roomTypeEtcFlg,
    } = hotelGoods;
    // 객실 개수
    const roomNumber = {
      sglNumber: 0,
      twnNumber: 0,
      trpNumber: 0,
      quaNumber: 0,
      etcNumber: 0,
    };
    // 룸 타입별 사용 여부 체크 한 개수
    const positiveNumber =
      roomTypeSglFlg! +
      roomTypeTwnFlg! +
      roomTypeTrpFlg! +
      roomTypeQudFlg! +
      roomTypeEtcFlg!;

    if (
      // 룸 타입별 관리 하지 않거나
      !roomTypeByTourNumberFlg ||
      // 하나도 체크하지 않았다면
      positiveNumber === 0
    ) {
      // 초기 값 설정
      roomNumber.twnNumber = Math.floor(this.tourNumber / 2);
      roomNumber.sglNumber = Math.ceil(this.tourNumber % 2);

      // 빈 오브젝트 반환
      return of(roomNumber);
    }

    return this.openRoomNumberSelector(hotelGoods).pipe(
      // 선택하지 않았다면 종료
      mergeMap((selection) => {
        if (selection) {
          return of(selection);
        }

        // return this.bookingApi
        //   .cancelBookingHomepage(this.booking!.id!, this.booking!)
        //   .pipe(
        //     mergeMap(() => {
        this.reset();
        return EMPTY;
        //   }),
        // );
      }),
    );
  }

  /**
   * 이용 가능한 최대 인원 수
   */
  private getAvailableNumber(
    hotelGoods: IHotelGoods,
    tourNumberHotelList: ICalendarInfo[],
  ): number {
    // 설정 없으면 무제한
    if (isNil(hotelGoods.capacity)) {
      return Infinity;
    }

    // 초기값은 최대 수용 인원
    let minNumber: number = hotelGoods.capacity;

    // 현재 비교중인 상품의 일자 목록
    const list = tourNumberHotelList.filter((goods) =>
      goods.goodsIdList?.includes(hotelGoods.id!),
    );

    list?.forEach((info) => {
      const availableNumber =
        (hotelGoods?.capacity ?? 0) - (info?.tourNumberTotal ?? 0);

      // 더 적은쪽 저장
      minNumber = Math.min(minNumber, availableNumber);
    });

    return minNumber;
  }

  private getAvailableHotelName(
    availableMap: Record<number, boolean>,
    hotelGoodsList: IHotelGoods[],
  ): string {
    let goodsNm = '';

    // 이용 가능 시설 상품 목록
    const availableList = Object.entries(availableMap)
      .filter(([, v]) => v)
      .map(([k]) => {
        return hotelGoodsList.find(({ id }) => id === +k);
      });

    // 이용 가능 상품 여러 개 있으면
    if (availableList.length > 1) {
      // 숙박 상품 판매 순서대로만 예약 가능?
      if (this.homeScreenService.homeScreen.sellSortForwardOnlyFlg) {
        // 마지막 상품 찾아서 반환
        const lastSort = availableList[0]!.sellSort;
        availableList.forEach((goods) => {
          if (lastSort !== goods?.sellSort) {
            return;
          }

          // 순서 동일한 상품 있으면 이름 추가
          if (goodsNm) {
            goodsNm += '\n';
          }

          goodsNm += goods!.goodsNm!;
        });

        return goodsNm;
      }

      // 순서대로만 예약 가능한게 아니라면 가장 앞 순위의 상품 반환
      return availableList[0]!.goodsNm!;
    }

    // 이용 가능 상품 1개 있으면
    if (availableList.length > 0) {
      // 판매 순서 정렬 후 가장 앞 순위의 상품 반환
      return availableList.sort(
        (a, b) => (a?.sellSort ?? 0) - (b?.sellSort ?? 0),
      )[0]!.goodsNm!;
    }

    // 불가
    return '마감';
  }

  private getAvailableHotelGoodsSimple(
    calendarInfoSimple: ICalendarInfoDisplay,
    hotelGoodsList: IHotelGoods[],
  ): IHotelGoods[] {
    return this.packagegoodsStaticService.getAvailableHotelGoods(
      this.tourNumber,
      this.packagegoods!.id!,
      calendarInfoSimple,
      hotelGoodsList,
    );
  }

  private getAvailableHotelGoods(
    hotelGoodsList: IHotelGoods[],
    calendarInfoList: number[][],
  ): IHotelGoods | undefined | null {
    // FIXME: 나고야 2인 예약 시 2인실 고정, 추후 서버에서 체크 시 제거
    // if (this.brandService.brand.id === 2) {
    //   if (this.packagegoods?.id === 2 && this.tourNumber < 3) {
    //     return (this.hotelGoodsList ?? [])[0];
    //   }
    // }

    let filteredHotelGoodsList = [...hotelGoodsList];
    calendarInfoList.forEach((info) => {
      filteredHotelGoodsList = filteredHotelGoodsList.filter((goods) =>
        info?.includes(goods.id!),
      );
    });

    return filteredHotelGoodsList[0] ?? null;
  }

  // FIXME: 임시 하드코딩
  /**
   * EWRC 아소야마나미 골프대회 메시지 표시
   * @deprecated EWRC 아소야마나미 전용으로만 임시 사용되므로 추후 필요시 재검토, 필요 없으면 삭제
   */
  private isSpecialDayOk(): Observable<boolean | null> {
    // EWRC 아니면 스킵
    if (this.brandService.brand.id !== 2) {
      return of(true);
    }

    let message: string = null!;

    // 아소 야마나미 패키지
    if (this.packagegoods?.id === 1) {
      if (
        new Date('2024-10-04') < new Date(this.returnDate) &&
        new Date('2024-10-11') > new Date(this.startDate)
      ) {
        message =
          '아소 우부야마 자선행사 골프대회로 인해 일정 중 하루는 관광쇼핑으로 대체됩니다.<br/>계속 진행하시려면 확인을 눌러주세요.';
      }

      if (
        new Date('2024-11-01') < new Date(this.returnDate) &&
        new Date('2024-11-08') > new Date(this.startDate)
      ) {
        message =
          '아소야마나미리조트 개장 기념배 골프대회 참가비 2천엔, 디너쇼 참가비 2천엔 추가 금액이 발생합니다.<br/>계속 진행하시려면 확인을 눌러주세요.';
      }

      if (
        new Date('2025-10-08') <= new Date(this.returnDate) &&
        new Date('2025-10-08') >= new Date(this.startDate)
      ) {
        message =
          '※<25년 10월 08일>에는 자선행사골프대회로 인해 라운딩없이 관광이나 쇼핑으로 대체됩니다.<br/>계속 진행하시려면 확인을 눌러주세요.';
      }

      if (
        new Date('2025-11-01') <= new Date(this.returnDate) &&
        new Date('2025-11-02') >= new Date(this.startDate)
      ) {
        message =
          '개장기념일 필수 참가(추가비용:￥7000)<br/>※11/1:골프 대회, 11/2:기념 만찬';
      }
    }

    if (message) {
      return this.dialogService.confirm(message);
    }

    return of(true);
  }

  private showNoAvailGoodsAlertAndClose(): void {
    const message = this.translateService.instant('ALERT.Goods_Not_Found');
    this.dialogService.alert(message).subscribe();
    this.onCancelClick();
    this.dialogRef.close();
  }
}
