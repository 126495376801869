<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">{{ getHeaderTitle() | translate }}</h1>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="modal-body">
      <!--룸 목록 -->
      <!-- <mat-radio-group
    class="room-radio-group"
    color="primary"
    [value]="data?.selected?.id"
  > -->
      <ul class="room-list">
        <li class="room-item" *ngFor="let item of this.selectableGoodsList">
          <label class="room-item-label">
            <ng-container
              *ngIf="
                selectableGoodsList.length > 1 &&
                getStatusByHotelGoods(item) as status
              "
            >
              <input
                class="room-radio"
                name="room"
                type="radio"
                [disabled]="
                  (tourNumberMap.get(item.id!) ?? 0) < 0 || status === '불가'
                "
                [value]="item.id"
                (change)="onRoomInputChange($event)"
              />
              {{ status }}
            </ng-container>

            <!-- 룸 이미지 -->
            <div class="room-img-wrapper">
              <img
                loading="lazy"
                class="room-img"
                [src]="getFirstImgUrl(item)"
              />
            </div>

            <!-- Radio 버튼 및 룸 이름 -->
            <div class="room-info-wrapper" *ngIf="!isSeletableGoodsOnlyOne()">
              <span class="room-name">
                {{ item.goodsNm }}
              </span>

              <!-- <div class="room-remain">
              <label class="room-remain-label">잔여</label>
              <span class="room-remain-content">{{
                tourNumberMap.get(item.id)
              }}</span>
            </div> -->
            </div>
          </label>
          <!-- 룸 둘러보기 -->
          <button
            class="room-info-button"
            mat-flat-button
            (click)="onRoomDetailButtonClick(item)"
          >
            {{ '숙박시설 둘러보기' | translate }}
          </button>
          <ng-container *ngIf="getHotelGoodsMessage(item) as message">
            <h3 class="room-message-title">{{ 'Note' | translate }}</h3>
            <span class="room-message-content">{{ message }}</span>
          </ng-container>
        </li>
      </ul>
      <!-- </mat-radio-group> -->
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-primary"
        (click)="close(selectedGoods)"
        [disabled]="!selectedGoods"
      >
        <!-- {{ 'BUTTON.Confirm_Booking' | translate }} -->
        {{ 'BUTTON.Ok' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="onCancelButtonClick()">
        {{ '예약진행취소' | translate }}
      </button>
    </div>
  </div>
</div>
