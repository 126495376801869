import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { BookingConfirmComponent } from './booking-confirm.component';

@NgModule({
  declarations: [BookingConfirmComponent],
  imports: [CommonModule, SharedModule],
  exports: [BookingConfirmComponent],
})
export class BookingConfirmModule {}
