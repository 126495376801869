<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">{{ '룸 타입' | translate }}</h1>
      <!-- <button type="button" class="btn-close" (click)="close()"></button> -->
    </div>
    <div class="modal-body">
      <ng-container *ngIf="dialogRef.data!.hotelGoods?.roomTypeEtcFlg">
        <h3 class="sub-title">ETC</h3>
        <app-tour-number
          [maxMessage]="tourNumberMaxMessage"
          [canSelectZero]="true"
          suffix="roomCnt"
          [max]="getMax('etc')"
          [disabled]="
            (dialogRef.data!.hotelGoods.roomTypeEtcCapacity ?? 0) <= etcMax ||
            (dialogRef.data!.hotelGoods.roomTypeEtcReadonlyFlg ?? 0) > 0
          "
          [ngModel]="etcNumber"
          (ngModelChange)="onRoomNumberUpdate($event, 'Etc')"
        ></app-tour-number>
      </ng-container>

      <ng-container *ngIf="dialogRef.data!.hotelGoods?.roomTypeQudFlg">
        <h3 class="sub-title">4인실</h3>
        <app-tour-number
          [maxMessage]="tourNumberMaxMessage"
          [canSelectZero]="true"
          suffix="roomCnt"
          [max]="getMax('qud')"
          [disabled]="
            (dialogRef.data!.hotelGoods.roomTypeQudCapacity ?? 0) <= qudMax ||
            (dialogRef.data!.hotelGoods.roomTypeQudReadonlyFlg ?? 0) > 0
          "
          [ngModel]="qudNumber"
          (ngModelChange)="onRoomNumberUpdate($event, 'Qud')"
        ></app-tour-number>
      </ng-container>

      <ng-container *ngIf="dialogRef.data!.hotelGoods?.roomTypeTrpFlg">
        <h3 class="sub-title">3인실</h3>
        <app-tour-number
          [maxMessage]="tourNumberMaxMessage"
          [canSelectZero]="true"
          suffix="roomCnt"
          [max]="getMax('trp')"
          [disabled]="
            (dialogRef.data!.hotelGoods.roomTypeTrpCapacity ?? 0) <= trpMax ||
            (dialogRef.data!.hotelGoods.roomTypeTrpReadonlyFlg ?? 0) > 0
          "
          [ngModel]="trpNumber"
          (ngModelChange)="onRoomNumberUpdate($event, 'Trp')"
        ></app-tour-number>
      </ng-container>

      <ng-container *ngIf="dialogRef.data!.hotelGoods?.roomTypeTwnFlg">
        <h3 class="sub-title">2인실</h3>
        <app-tour-number
          [maxMessage]="tourNumberMaxMessage"
          [canSelectZero]="true"
          suffix="roomCnt"
          [max]="getMax('twn')"
          [disabled]="
            (dialogRef.data!.hotelGoods.roomTypeTwnCapacity ?? 0) <= twnMax ||
            (dialogRef.data!.hotelGoods.roomTypeTwnReadonlyFlg ?? 0) > 0
          "
          [ngModel]="twnNumber"
          (ngModelChange)="onRoomNumberUpdate($event, 'Twn')"
        ></app-tour-number>
      </ng-container>

      <ng-container *ngIf="dialogRef.data!.hotelGoods?.roomTypeSglFlg">
        <h3 class="sub-title">1인실</h3>
        <app-tour-number
          [maxMessage]="tourNumberMaxMessage"
          [canSelectZero]="true"
          suffix="roomCnt"
          [max]="getMax('sgl')"
          [disabled]="
            (dialogRef.data!.hotelGoods.roomTypeSglCapacity ?? 0) <= sglMax ||
            (dialogRef.data!.hotelGoods.roomTypeSglReadonlyFlg ?? 0) > 0
          "
          [ngModel]="sglNumber"
          (ngModelChange)="onRoomNumberUpdate($event, 'Sgl')"
        ></app-tour-number>
      </ng-container>
    </div>

    <div class="modal-footer">
      <button
        class="btn btn-primary"
        [disabled]="!canOk"
        (click)="onOkButtonClick()"
      >
        {{ 'BUTTON.Ok' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="close(null)">
        {{ 'BUTTON.Cancel' | translate }}
      </button>
    </div>
  </div>
</div>
