import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { TourNumberComponent } from './tour-number.component';

@NgModule({
  declarations: [TourNumberComponent],
  imports: [CommonModule, SharedModule],
  exports: [TourNumberComponent],
})
export class TourNumberModule {}
