<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="flight-select-modal" *ngIf="!isApp">
      <div
        class="flight-select-guide"
        [ngClass]="{ return: dialogRef.data!.title.includes('귀국') }"
      >
        {{ dialogRef.data!.title | translate }}
      </div>

      <a class="close-button-wrapper" (click)="onCloseButtonClick()">
        <img
          class="close-button"
          src="/assets/publishing/include/images/ico/ico_close01.png"
        />
      </a>

      <div>
        <div class="flight-header">
          <div>{{ 'Airline' | translate }}</div>
          <div>{{ 'Flight' | translate }}</div>
          <div>{{ 'Start Time' | translate }}</div>
          <div>{{ 'Arrival Time' | translate }}</div>
        </div>
        <a
          *ngFor="let f of dialogRef.data!.flights"
          class="flight-body"
          (click)="onFlightItemClick(f)"
        >
          <div>{{ f.airplaneName }}</div>
          <div>{{ f.airplaneCode }}</div>
          <div>{{ f.startTime }}</div>
          <div>{{ f.destinationTime }}</div>
        </a>
      </div>
    </div>
    <ng-container *ngIf="isApp">
      <div class="modal-header">
        <i class="bi bi-geo"></i>
        <span>{{ dialogRef.data!.title | translate }}</span>
      </div>
      <div class="modal-body">
        <div class="list-group list-group-flush">
          <button
            type="button"
            class="list-group-item list-group-item-action"
            [ngClass]="{ selected: selectedFlight === f }"
            *ngFor="let f of dialogRef.data!.flights"
            (click)="selectedFlight = f"
          >
            <span class="name">{{ f.airplaneName }}/{{ f.airplaneCode }}</span>
            <div class="time-wrapper">
              <span class="time-range"
                >{{ f.startTime }} ~ {{ f.destinationTime }}</span
              >
              <span class="time"
                >{{ 'directFlight' | translate }}
                {{ totalTimes(f.startTime!, f.destinationTime!) }}</span
              >
            </div>
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedFlight"
          (click)="onOkButtonClick(selectedFlight!)"
        >
          {{ 'BUTTON.Ok' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="onCloseButtonClick()"
        >
          {{ 'BUTTON.Cancel' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
