<div class="data-wrapper" [class.opacity-25]="isDisabled">
  <!-- 일자 -->
  <div class="date d-flex justify-content-center align-items-end">
    {{ date | date : 'd' }}
  </div>

  <div class="data d-flex justify-content-center align-items-start">
    <!-- 상품명 -->
    <ng-container
      class="data"
      *homeScreen="'bookingCalendarType'; value: 'GOODS'"
    >
      {{ goodsNm || '-' }}
    </ng-container>

    <!-- 재고 -->
    <ng-container
      class="data"
      *homeScreen="'bookingCalendarType'; value: 'INVENTORY'"
    >
      {{ !remainCnt || remainCnt < 0 ? '-' : remainCnt }}
    </ng-container>

    <!-- 가격 -->
    <ng-container
      class="data"
      *homeScreen="'bookingCalendarType'; value: 'PRICE'"
    >
      {{
        !totalPrice || totalPrice < 0
          ? 0
          : (totalPrice / 10000 | number : '1.1-1') + '~'
      }}
    </ng-container>
  </div>
</div>
