<div class="person-box" [class.disabled]="disabled">
  <button
    class="btn-step-down"
    type="button"
    [disabled]="disabled"
    (click)="setTourNumber(-1)"
  >
    <i class="bi bi-dash"></i>
  </button>

  <span class="value">
    {{ tourNumber }}

    <ng-container *ngIf="showSuffix">
      {{ suffix || 'peopleCntUnit' | translate }}
    </ng-container>
  </span>

  <button
    class="btn-step-up"
    type="button"
    [disabled]="disabled"
    (click)="setTourNumber(1)"
  >
    <i class="bi bi-plus"></i>
  </button>
</div>
