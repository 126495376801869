import { Component, ElementRef, OnInit } from '@angular/core';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IMrhst } from '../../../lib/repository/mrhst/mrhst.model';
import { IPackagegoods } from '../../../lib/repository/packagegoods/packagegoods.model';
import { Utils } from '../../../lib/utils';
import { DialogService } from '../../services/dialog.service';
import { PackagegoodsDetailService } from '../../services/packagegoods-detail.service';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';

export interface IBookingConfirmData {
  startName: string;
  destinationName: string;
  tourNumber: number;
  nights: number;
  hotelGoods: IHotelGoods;
  mrhst: IMrhst;
  startDate: string;
  returnDate: string;
  packagegoods: IPackagegoods;
}

export interface IBookingConfirmResult {
  result: boolean;
  tourNumber: number;
}

/**
 * 예약 등록 전 확인 창
 */
@Component({
  selector: 'app-booking-confirm',
  templateUrl: './booking-confirm.component.html',
  styleUrls: ['./booking-confirm.component.scss'],
})
export class BookingConfirmComponent extends DialogAbstract implements OnInit {
  override dialogRef!: DialogRef<this, any, IBookingConfirmData>;

  tourNumber?: number;

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private dialogService: DialogService,
    private packagegoodsDetailService: PackagegoodsDetailService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.tourNumber = this.dialogRef.data!.tourNumber;
  }

  /**
   * 시설 안내 메시지
   */
  getMessageByHotelGoods(): string {
    let hotelGoodsMessage =
      this.dialogRef.data!.hotelGoods.roomNoteMessage ?? '';

    const { mrhstNoteMessage } = this.dialogRef.data!.hotelGoods?.mrhst || {};

    if (mrhstNoteMessage) {
      hotelGoodsMessage = `${mrhstNoteMessage}\n\n${hotelGoodsMessage}`;
    }

    // if (this.data.nights >= 7) {
    //   if (hotelGoodsMessage) {
    //     hotelGoodsMessage += '\n\n';
    //   }
    //   return `${hotelGoodsMessage}※주말 1회는 쇼핑 및 관광으로 대체될 수 있습니다.`;
    // }

    return `${hotelGoodsMessage}`;
  }

  /**
   * 숙박시설 둘러보기 버튼 클릭
   */
  onRoomDetailButtonClick(hotelGoods: IHotelGoods): void {
    this.packagegoodsDetailService
      .openHotelGoodsDescriptionDialog$(hotelGoods)
      .subscribe();
  }

  /**
   * 확인 버튼
   */
  onPositiveButtonClick(): void {
    this.dialogRef.close(<IBookingConfirmResult>{
      result: true,
      tourNumber: this.tourNumber,
    });
  }

  /**
   * 취소 버튼
   */
  onNegativeButtonClick(): void {
    this.dialogRef.close(<IBookingConfirmResult>{
      result: false,
    });
  }

  /**
   * imgUrlJson 첫번째 URL 반환
   */
  getFirstImgUrl(hotelGoods: IHotelGoods): string {
    return (
      hotelGoods.imgFileDataList?.[0]?.path ||
      Utils.getParsedJson(hotelGoods.imgUrl, [])[0]
    );
  }
}
