import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import dayjs, { Dayjs } from 'dayjs';
import { AbstractForm } from './abstract.form';

/**
 * 년월 선택기 이벤트
 */
export interface YearMonthChange {
  /**
   * 선택 년
   */
  year: number;

  /**
   * 선택 월
   *
   * 월 표시하지 않으면 반환하지 않음
   */
  month?: number;

  /**
   * 시작일
   */
  fromDate: string;

  /**
   * 종료일
   */
  toDate: string;
}

/**
 * 년월 선택기 컴포넌트 추상 클래스
 */
@Component({ template: '' })
export abstract class AbstractYearMonthSelectorComponent
  extends AbstractForm
  implements AfterContentInit
{
  /**
   * 사용 안함
   *
   * 자식이 구현하지 않아도 되도록 이곳에 정의
   */
  override formGroup!: FormGroup;

  /**
   * 월 목록 0~11
   */
  monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  /**
   * 선택 연도
   */
  selectedYear?: number;

  /**
   * 선택 월
   */
  selectedMonth?: number;

  /**
   * 유효성 검사용 최대 연도
   */
  minYear?: number;

  /**
   * 유효성 검사용 최소 연도
   */
  maxYear?: number;

  /**
   * 연도 목록
   */
  @Input() yearList?: number[];

  /**
   * 비활성 여부
   */
  @Input() isDisabled = false;

  /**
   * 월 표시 여부
   */
  @Input() hasMonth = true;

  /**
   * 화살표 표시 여부
   */
  @Input() hasArrow = true;

  /**
   * 년월 변경시
   */
  @Output() yearMonthChange: EventEmitter<YearMonthChange> = new EventEmitter();

  constructor() {
    super();
  }

  ngAfterContentInit(): void {
    if (!this.yearList?.length) {
      throw new Error('yearList is required.');
    }

    // 연도 범위 검사용 최소/최대값
    this.minYear = Math.min(...this.yearList);
    this.maxYear = Math.max(...this.yearList);
  }

  override getValue(): YearMonthChange {
    const fromMonth = this.hasMonth ? this.selectedMonth! : 0;
    const toMonth = this.hasMonth ? this.selectedMonth! : 11;
    const fromDayjs = dayjs(new Date(this.selectedYear!, fromMonth, 1));
    const toDayjs = dayjs(new Date(this.selectedYear!, toMonth)).endOf('month');

    const event: YearMonthChange = {
      year: this.selectedYear!,
      month: this.hasMonth ? this.selectedMonth : null!,
      fromDate: fromDayjs.format('YYYY-MM-DD'),
      toDate: toDayjs.format('YYYY-MM-DD'),
    };

    return event;
  }

  override setValue(value: string | Date | Dayjs): void {
    const theDayjs = dayjs(value || undefined);
    this.selectedYear = theDayjs.year();
    this.selectedMonth = theDayjs.month();
  }

  /**
   * 연도 변경시
   * @param year 연도
   */
  onYearChange(year: number): void {
    this.selectedYear = Number(year);
    this.yearMonthChange.emit(this.getValue());
  }

  /**
   * 월 변경시
   * @param month 0~11의 월 인덱스
   */
  onMonthChange(month: number): void {
    this.selectedMonth = Number(month);
    this.yearMonthChange.emit(this.getValue());
  }

  /**
   * 화살표 클릭시
   * @param direction 오른쪽: 1, 왼쪽: -1
   */
  onArrowClick(direction: 1 | -1): void {
    let theDayjs = dayjs(new Date(this.selectedYear!, this.selectedMonth!));

    // 월 표시하면
    if (this.hasMonth) {
      // 월 이동
      theDayjs = theDayjs.add(direction, 'month');
    }
    // 아니면
    else {
      // 연도 이동
      theDayjs = theDayjs.add(direction, 'year');
    }

    // 유효성 검사 전 임시 저장
    const yearMaybeInvalid = theDayjs.year();

    // 선택 가능 범위 내에 있는지 검사
    if (yearMaybeInvalid < this.minYear! || yearMaybeInvalid > this.maxYear!) {
      return;
    }

    this.selectedYear = yearMaybeInvalid;
    this.selectedMonth = theDayjs.month();
    this.yearMonthChange.emit(this.getValue());
  }
}
