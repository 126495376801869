import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  Type,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import dayjs from 'dayjs';
import { AbstractCalendarDataComponent } from 'src/lib/components/abstract/abstract-calendar-data.component';
import { Utils } from 'src/lib/utils';

/**
 * 달력 컴포넌트 추상 클래스
 */
@Component({ template: '' })
export abstract class AbstractCalendarComponent
  implements AfterContentInit, AfterViewInit, OnChanges
{
  /**
   * 달력 행렬
   */
  date2dList?: string[][];

  /**
   * 오늘
   */
  today = dayjs();

  /**
   * 컴포넌트 생성 위치 목록
   */
  @ViewChildren('viewContainerRef', { read: ViewContainerRef })
  viewContainerRefList?: QueryList<ViewContainerRef>;

  /**
   * 일자별 컴포넌트 맵
   */
  protected componentByDateMap?: Map<string, AbstractCalendarDataComponent>;

  /**
   * 달력 일자 목록
   */
  @Input() dateList?: string[];

  /**
   * 선택한 일자
   */
  @Input() selectedDate?: string;

  /**
   * 일자 클릭 가능
   */
  @Input() canClickDate = true;

  /**
   * 로딩중
   */
  @Input() isLoading = false;

  /**
   * 달력 데이터 컴포넌트
   */
  @Input() calendarDataComponent?: Type<AbstractCalendarDataComponent>;

  /**
   * 데이터 컴포넌트에 입력할 데이터
   */
  @Input() componentData: any;

  /**
   * 초기화시
   */
  @Output() init: EventEmitter<this> = new EventEmitter();

  /**
   * 일자 클릭시
   */
  @Output() dateClick: EventEmitter<AbstractCalendarDataComponent> =
    new EventEmitter();

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.setDateListIfNotProvided();
    this.setDate2dList();
  }

  ngAfterViewInit(): void {
    if (!this.date2dList?.length) {
      throw new Error('date2dList not created.');
    }

    this.init.emit(this);
    this.createDataComponent();
  }

  ngOnChanges({ dateList, componentData }: SimpleChanges): void {
    if (dateList?.currentValue) {
      this.setDate2dList();
    }

    if (componentData?.currentValue) {
      this.createDataComponent();
    }
  }

  /**
   * 일자 클릭시
   */
  onDateClick(date: string): void {
    // 컴포넌트 외부에서 실행하는 경우 일자 없을수 있음
    if (!date) {
      return;
    }

    // 클릭 불가능하면
    if (!this.canClickDate) {
      return;
    }

    this.selectedDate = date;
    this.dateClick.emit(this.componentByDateMap?.get(date));
  }

  /**
   * 컴포넌트 초기화시 일자 목록 입력되지 않았으면 이번달을 기준으로 생성
   */
  protected setDateListIfNotProvided(): void {
    if (this.dateList?.length) {
      return;
    }

    // 시작일: 월초
    const fromDate = this.today.startOf('month');
    // 종료일: 월말
    const toDate = this.today.endOf('month');
    // 일자 목록
    this.dateList = Utils.getDateList(fromDate, toDate);
  }

  /**
   * 달력 행렬 설정
   */
  protected setDate2dList(): void {
    // 데이터 컴포넌트 생성 완료되기 전까지 감지 해제
    this.changeDetectorRef.detach();
    this.date2dList = [];
    let row = 0;

    // 6행
    for (let i = 0; i < 6; i += 1) {
      this.date2dList[i] = [];
      // 7열
      for (let j = 0; j < 7; j += 1) {
        // 초기화
        this.date2dList[i][j] = null!;
      }
    }

    this.dateList?.forEach((date, i) => {
      // 요일에 맞는 위치에 입력
      const day = dayjs(date).day();
      this.date2dList![row][day] = date;

      // 토요일이면
      if (day === 6) {
        // 다음 행으로
        row += 1;
      }

      // 이번 반복이 마지막 행이고
      if (i === this.dateList!.length - 1) {
        // 6주차 첫번째 열에 값이 없으면
        if (this.date2dList![5][0] === null) {
          // 6주차 삭제
          this.date2dList!.pop();
        }
      }
    });

    // 변경 감지
    // 없으면 detach 했으므로 데이터 화면에 표시하지 않음
    this.changeDetectorRef.detectChanges();
  }

  /**
   * 데이터 컴포넌트 생성
   */
  private createDataComponent(): void {
    // 데이터 컴포넌트 입력하지 않았다면
    if (!this.calendarDataComponent) {
      return;
    }

    this.componentByDateMap = new Map();

    // 데이터 컴포넌트의 인덱스와 일자의 인덱스가 일치하도록 주의
    this.viewContainerRefList?.forEach((viewContainerRef, index) => {
      // 초기화
      viewContainerRef.clear();
      // 컴포넌트 생성
      const component = viewContainerRef.createComponent(
        this.calendarDataComponent!,
      );
      // 컴포넌트 인풋 입력
      const { instance } = component;
      instance.date = this.dateList![index];
      instance.componentData = this.componentData;
      // 맵에 저장
      this.componentByDateMap?.set(this.dateList![index], component.instance);
    });

    // 변경 감지
    this.changeDetectorRef.detectChanges();
    // 감지 재개
    this.changeDetectorRef.reattach();
  }
}
