import { Component, ElementRef, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { HomeScreenService } from '../../../lib/services/home-screen.service';
import { Utils } from '../../../lib/utils';
import { DialogService } from '../../services/dialog.service';
import { PackagegoodsDetailService } from '../../services/packagegoods-detail.service';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';

/**
 * 룸 선택 시 사용될 데이터
 */
export interface IHotelRoomSelectorData {
  /**
   * 상품 목록
   */
  goodsList: IHotelGoods[];
  /**
   * 통계 목록
   */
  calendarInfoList: number[][];
  /**
   * 선택한 상품
   */
  selected: IHotelGoods;
  /**
   * 설정한 인원수
   */
  tourNumber: number;
  /**
   * 설정한 기간
   */
  nights: number;
}

/**
 * 룸 선택 컴포넌트
 */
@Component({
  selector: 'app-hotel-room-selector',
  templateUrl: './hotel-room-selector.component.html',
  styleUrls: ['./hotel-room-selector.component.scss'],
})
export class HotelRoomSelectorComponent
  extends DialogAbstract
  implements OnInit
{
  override dialogRef!: DialogRef<this, any, IHotelRoomSelectorData>;
  /**
   * 선택한 상품
   */
  selectedGoods: IHotelGoods = null!;

  /**
   * 상품별 이용 가능한 최대 인원 수
   */
  tourNumberMap: Map<number, number> = new Map();

  /**
   * 선택 가능한 상품 목록
   */
  selectableGoodsList: IHotelGoods[] = [];

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private packagegoodsDetailService: PackagegoodsDetailService,
    private dialogService: DialogService,
    private homeScreenService: HomeScreenService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.dialogRef.data!.selected) {
      this.selectedGoods = this.dialogRef.data!.selected;
    }

    // 상품 하나라면 기본 선택
    if (this.dialogRef.data!.goodsList.length === 1) {
      [this.selectedGoods] = this.dialogRef.data!.goodsList;
      this.dialogRef.data!.selected = this.selectedGoods;
    }

    // 상품 목록 판매 순서로 재정렬
    this.dialogRef.data!.goodsList = this.dialogRef.data!.goodsList.sort(
      (a, b) => (a.sellSort ?? 0) - (b.sellSort ?? 0),
    );

    let filteredHotelGoodsList = [...this.dialogRef.data!.goodsList];
    this.dialogRef.data!.calendarInfoList.forEach((info) => {
      filteredHotelGoodsList = filteredHotelGoodsList.filter((goods) =>
        info?.includes(goods.id!),
      );
    });

    if (this.homeScreenService.homeScreen.sellSortForwardOnlyFlg) {
      const firstSellSort = filteredHotelGoodsList[0].sellSort;
      this.selectableGoodsList = filteredHotelGoodsList.filter(
        ({ sellSort }) => firstSellSort === sellSort,
      );

      if (!this.selectableGoodsList.length) {
        this.selectableGoodsList = [filteredHotelGoodsList[0]];
      }
    } else {
      this.selectableGoodsList = filteredHotelGoodsList;
    }

    if (this.selectableGoodsList.length === 1) {
      [this.selectedGoods] = this.selectableGoodsList;
    }

    // 상품별 최대 인원수 캐싱
    // this.dialogRef.data!.goodsList.forEach((goods, index) => {
    //   const availableNumber = this.getAvailableNumber(goods);
    //   this.tourNumberMap.set(goods.id!, availableNumber);

    //   // 판매 순서가 동일하거나
    //   if (this.selectedGoods?.sellSort === goods.sellSort) {
    //     this.selectableGoodsList.push(goods);
    //     return;
    //   }

    //   // 선택 가능한 상품이 없고 현재 상품이 판매 가능하면
    //   // 선택 가능한 상품 목록에 추가
    //   if (this.selectableGoodsList.length < 1 && availableNumber >= 0) {
    //     const isNoHasCapacity = this.dialogRef.data!.goodsList
    //       .slice(index + 1)
    //       .every(
    //         (hotelGoods) =>
    //           hotelGoods.capacity === this.getAvailableNumber(hotelGoods),
    //       );

    //     // 아직 팔리지 않음
    //     if (isNoHasCapacity) {
    //       this.selectableGoodsList.push(goods);
    //     }
    //   }
    // });
  }

  /**
   * 룸 선택 값 변경
   */
  onRoomInputChange(event: Event): void {
    const target = <HTMLInputElement>event.target;

    const foundGoods = this.dialogRef.data!.goodsList.find(
      (goods) => goods.id === +target.value,
    );

    const message = foundGoods?.roomNoteMessage;

    if (message) {
      // 참고 사항 표시
      this.dialogService
        .confirm(message)
        .pipe(
          tap((result) => {
            if (!result) {
              // 미 동의시 Radio 선택 해제, 선택한 상품 null 설정
              target.checked = false;
              this.selectedGoods = null!;
            } else {
              // 동의하면 상품 선택
              this.selectedGoods = foundGoods;
            }
          }),
        )
        .subscribe();
    } else {
      // 참고 사항 없음
      this.selectedGoods = foundGoods!;
    }
  }

  onCancelButtonClick(): void {
    this.dialogService
      .confirm('지금까지 진행했던 예약이 취소됩니다.<br/>취소하시겠습니까?')
      .pipe(
        tap((res) => {
          if (res) {
            this.dialogRef.close();
          }
        }),
      )
      .subscribe();
  }

  /**
   * 숙박시설 둘러보기 버튼 클릭
   */
  onRoomDetailButtonClick(hotelGoods: IHotelGoods): void {
    this.packagegoodsDetailService
      .openHotelGoodsDescriptionDialog$(hotelGoods)
      .subscribe();
  }

  /**
   * 선택 가능한 상품 단일 여부
   */
  isSeletableGoodsOnlyOne(): boolean {
    return this.selectableGoodsList.length === 1;
  }

  /**
   * 해더 제목
   */
  getHeaderTitle(): string {
    // 선택 가능한 상품 하나일때, 상품명을 제목으로 설정
    if (this.isSeletableGoodsOnlyOne()) {
      return this.selectableGoodsList[0].goodsNm!;
    }

    return '룸 타입';
  }

  /**
   * 상품 참고 사항
   */
  getHotelGoodsMessage(hotelGoods: IHotelGoods): string {
    const hotelGoodsMessage = hotelGoods.roomNoteMessage ?? '';

    // if (this.dialogRef.data!.nights >= 7) {
    //   if (hotelGoodsMessage) {
    //     hotelGoodsMessage += '\n\n';
    //   }
    //   return `${hotelGoodsMessage}※주말 1회는 쇼핑 및 관광으로 대체될 수 있습니다.`;
    // }

    return `${hotelGoodsMessage}`;
  }

  /**
   * 상품 선택 가능 상태
   */
  getStatusByHotelGoods(hotelGoods: IHotelGoods): string {
    // if ((this.tourNumberMap.get(hotelGoods.id!) ?? 0) < 0) {
    //   return '마감';
    // }

    // const selectedIndex = this.dialogRef.data!.goodsList.findIndex(
    //   (goods) => goods.id === this.dialogRef.data!.selected?.id
    // );
    // const currentIndex = this.dialogRef.data!.goodsList.findIndex(
    //   (goods) => goods.id === hotelGoods.id
    // );

    // if (selectedIndex < currentIndex) {
    //   return '불가';
    // }

    return '선택';
  }

  /**
   * 이용 가능한 최대 인원 수
   */
  // getAvailableNumber(hotelGoods: IHotelGoods): number {
  //   // 설정 없으면 무제한
  //   if (isNil(hotelGoods.capacity)) {
  //     return Infinity;
  //   }

  //   // 최소 수용 가능 수
  //   let minNumber: number = hotelGoods.capacity;

  //   // 현재 상품의 통계만 가져옴
  //   const list = this.dialogRef.data!.calendarInfoList.filter((goods) =>
  //     goods.goodsIdList?.includes(hotelGoods.id!),
  //   );

  //   list.forEach((info) => {
  //     // 상품 수용 가능수 - (배정된 인원수 + 설정한 인원수)
  //     const available =
  //       (hotelGoods.capacity ?? 0) -
  //       ((info?.tourNumberTotal ?? 0) + this.dialogRef.data!.tourNumber);

  //     // 더 적은 수로 변경
  //     minNumber = Math.min(minNumber, available);
  //   });

  //   // 통계 없으면
  //   if (!list.length) {
  //     // 상품 수용 가능수 - 설정한 인원수
  //     minNumber = hotelGoods.capacity - this.dialogRef.data!.tourNumber;
  //   }

  //   return minNumber;
  // }

  /**
   * imgUrlJson 첫번째 URL 반환
   */
  getFirstImgUrl(hotelGoods: IHotelGoods): string {
    let imgUrlList: string[] = [];

    const fileDataPathList = hotelGoods!.imgFileDataList?.map(
      (file) => file.path!,
    );

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(hotelGoods!.imgUrl!, []);
    }

    return imgUrlList[0];
  }
}
