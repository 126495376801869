import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { Utils } from 'src/lib/utils';
import { DialogRef } from '../../dialog-ref';
import { DialogAbstract } from '../../dialog.abstract';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent extends DialogAbstract implements OnInit {
  override dialogRef!: DialogRef<
    this,
    any,
    {
      startDate: string;
      dateList: string[];
    }
  >;

  selectedDate: any;

  @HostBinding('class.app')
  get isApp(): boolean {
    return Utils.isMobileSize();
  }

  constructor(protected override elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  onDateItemClick(date: string): void {
    this.dialogRef.close(date);
  }

  onOkButtonClick(date: string): void {
    this.onDateItemClick(date);
  }

  onCloseButtonClick(): void {
    this.dialogRef.close();
  }

  public totalTimes(startTime: string, endTime: string): string {
    const splitedStart = startTime.split(':');
    const startDate = new Date(0, 0, 0, +splitedStart[0], +splitedStart[1]);

    const splitedEnd = endTime.split(':');
    const endDate = new Date(0, 0, 0, +splitedEnd[0], +splitedEnd[1]);

    endDate.setHours(
      endDate.getHours() - startDate.getHours(),
      endDate.getMinutes() - startDate.getMinutes(),
    );

    const minutes = endDate.getMinutes();
    const hours = endDate.getHours();

    return `${hours}시간 ${minutes}분`;
  }

  getNights(date: string): number {
    const startDate = new Date(this.dialogRef.data!.startDate);
    const returnDate = new Date(date);
    return (returnDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  }
}
