import { Component, OnInit } from '@angular/core';
import { AbstractCalendarDataComponent } from 'src/lib/components/abstract/abstract-calendar-data.component';
import { IPackagegoodsBookingCalendarDto } from 'src/lib/repository/packagegoods/packagegoods.model';

@Component({
  selector: 'trnty-booking-calendar-type1-data',
  templateUrl: './booking-calendar-type1-data.component.html',
  styleUrls: ['./booking-calendar-type1-data.component.scss'],
})
export class BookingCalendarType1DataComponent
  extends AbstractCalendarDataComponent<IPackagegoodsBookingCalendarDto[]>
  implements OnInit
{
  /**
   * 가격
   */
  totalPrice?: number;

  /**
   * 재고
   */
  remainCnt?: number;

  /**
   * 상품명
   */
  goodsNm?: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  override setValue(): void {
    const filtered = this.componentData?.filter(
      ({ startDate }) => startDate === this.date,
    );

    const [first] = filtered || [];

    this.goodsNm = first?.hotelGoods?.goodsNm;
    this.remainCnt = first?.remainCnt;
    this.totalPrice = first?.totalPrice;
  }

  override setDisabled(): void {
    this.isDisabled = !this.goodsNm || !this.remainCnt || this.remainCnt < 0;
  }
}
