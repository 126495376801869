import { AfterContentInit, Component, HostBinding, Input } from '@angular/core';

/**
 * 달력 컴포넌트의 데이터 표시 컴포넌트 추상클래스
 */
@Component({ template: '' })
export abstract class AbstractCalendarDataComponent<T = any>
  implements AfterContentInit
{
  /**
   * 선택 불가 여부
   */
  isDisabled = false;

  /**
   * 일자 YYYY-MM-DD
   */
  @Input() @HostBinding('attr.date') date = '';

  /**
   * 데이터
   */
  @Input() componentData?: T;

  constructor() {}

  ngAfterContentInit(): void {
    // 자식 컴포넌트의 ngOnInit과 겹치지 않게 하기 위해 ngAfterContentInit 이용
    this.setValue();
    this.setDisabled();
  }

  /**
   * 컴포넌트 데이터 설정
   */
  abstract setValue(): void;

  /**
   * 비활성 여부 설정
   */
  abstract setDisabled(): void;
}
