<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-body">
      <div class="d-flex gap-4 flex-column flex-sm-row">
        <div class="confirm-wrapper">
          <!-- <span class="fw-bold fs-5">★예약이 완료되었습니다★</span><br /> -->
          상품명 : {{ dialogRef.data!.packagegoods.title }}<br />
          출국일 : {{ dialogRef.data!.startDate }}<br />
          귀국일 : {{ dialogRef.data!.returnDate }}<br />
          여행 기간 : {{ dialogRef.data!.nights }}박
          {{ dialogRef.data!.nights + 1 }}일<br />
          <div>인원 : {{ tourNumber }}명</div>
          <br />
          출발지 : {{ dialogRef.data!.startName }}<br />
          목적지 : {{ dialogRef.data!.destinationName }}<br />
          <div>
            숙박 시설 : {{ dialogRef.data!.mrhst.mrhstNm }}({{
              dialogRef.data!.hotelGoods.goodsNm
            }})
            <!-- <button
          class="btn btn-primary"
          type="button"
          (click)="onRoomDetailButtonClick(dialogRef.data!.hotelGoods)"
        >
          숙박시설 둘러보기
        </button> -->
          </div>
          <br />
          {{ getMessageByHotelGoods() }}<br />
          <!-- 일정을 다시 정하시려면 예약취소 버튼을 눌러주세요. -->
          진행하시겠습니까?
        </div>
        <div>
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              {{ dialogRef.data!.hotelGoods.goodsNm }}
            </h1>
          </div>
          <div class="room-item">
            <!-- 룸 이미지 -->
            <div class="room-img-wrapper">
              <img
                loading="lazy"
                class="room-img"
                [src]="getFirstImgUrl(dialogRef.data!.hotelGoods)"
              />
            </div>
            <!-- 룸 둘러보기 -->
            <!-- <button
          class="room-info-button"
          mat-flat-button
          (click)="onRoomDetailButtonClick(dialogRef.data!.hotelGoods)"
        >
          {{ '숙박시설 둘러보기' | translate }}
        </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="onPositiveButtonClick()"
      >
        {{ 'BUTTON.Confirm_Booking' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="onNegativeButtonClick()"
      >
        {{ 'BUTTON.Cancel' | translate }}
      </button>
    </div>
  </div>
</div>
